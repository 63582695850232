import { Box, Divider, IconButton, Typography, Button } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useSelector, useDispatch } from "react-redux";
import { clearTableData } from "src/redux/slices/tableSlice";
import { deleteCartBySessionId } from "src/redux/slices/cardSlice";
import useSession from "src/utils/useSession";
import { selectedVenue } from "src/utils/venueSelectionUtils";

const SwitchOrderTypeModal = ({
  width,
  open,
  onClose,
  setCurrentTab,
}) => {
  const { isPickUp, isDineIn } = useSelector((state) => state.cardSlice);
  let dispatch = useDispatch();
  const sessionInfo = useSession();

  const dropTbale = async () => {
     if (isDineIn === true && isPickUp === true) {
      selectedVenue(dispatch,"isPickUp", true);
      setCurrentTab("pickUp");
      dispatch(clearTableData());
      onClose();
    } else if (isDineIn === true && isPickUp === false) {
      let response = await dispatch(
        deleteCartBySessionId(sessionInfo?.sessionId)
      );
      if (response?.status === 204) {
        selectedVenue(dispatch,"isPickUp", true);
        setCurrentTab("pickUp");
        dispatch(clearTableData());
        onClose();
      }
    } else if (isDineIn === false && isPickUp === true) {
      let response = await dispatch(
        deleteCartBySessionId(sessionInfo?.sessionId)
      );
      if (response?.status === 204) {
        selectedVenue(dispatch,"isDineIn", true);
        setCurrentTab("dineIn");
        dispatch(clearTableData());
        onClose();
      }
    }
  };

  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <>
              <Typography
                sx={{
                  color: "#F08203",
                  fontWeight: "700",
                  fontSize: "25px",
                }}
              >
                Confirmation
              </Typography>
              <IconButton aria-label="close" onClick={() => onClose(!open)}>
                <Iconify
                  sx={{
                    height: 25,
                    width: 25,
                  }}
                  icon="mdi:close"
                  color="#fca92e"
                />
              </IconButton>
            </>
          </Box>
        </DialogTitle>
        <Box>
          <Divider />
        </Box>
        <DialogContent sx={{ typography: "body2" }}>
          <Typography mt={3} mb={3}>
            Are you sure you want to switch the mode ?
          </Typography>
          <Box
            mt={3}
            mb={4}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Button
              onClick={onClose}
              type="button"
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                height: "40px",
                fontSize: "16px",
              }}
            >
              Cancel
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              ></Typography>
            </Button>
            <Button
              onClick={dropTbale}
              type="button"
              variant="contained"
              color="primary"
              sx={{
                width: "100%",
                height: "40px",
                fontSize: "16px",
              }}
            >
              Continue
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              ></Typography>
            </Button>
          </Box>
        </DialogContent>
      </ConfirmDialog>
    </>
  );
};
export default SwitchOrderTypeModal;
