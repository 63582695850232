import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useSnackbar } from "src/components/snackbar";
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfileInfo } from "src/redux/slices/loginSlice";
import api from "src/utils/axios";
import { Controller } from "react-hook-form";
export default function UserCarInformation() {
  const dispatch = useDispatch();
  const { cardItems } = useSelector((state) => state?.cardSlice);
  const { isLoggedIn, user } = useSelector((state) => state.login);
  const { enqueueSnackbar } = useSnackbar();

  const carColors = [
    "White",
    "Silver",
    "Black",
    "Grey",
    "Blue",
    "Red",
    "Beige",
    "Maroon",
    "Green",
    "Brown",
    "Gold",
    "Yellow",
    "Orange",
    "Purple",
    "Teal",
    "Copper",
    "Burgundy",
    "Bronze",
    "Pink",
    "Turquoise",
    "Other",
  ];

  const schema = Yup.object().shape({
    carNumber: Yup.string().required("Car number is required"),
    carColor: Yup.string().required("Car color is required"),
  });

  const defaultValues = {
    carNumber: user?.carNumber ?? cardItems?.data?.customer?.carNumber ?? "",
    carColor: user?.carColor ?? cardItems?.data?.customer?.carColor ?? "",
    notes: cardItems?.data?.customer?.notes ?? "",
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      let carInfoDetails = {
        carNumber: data.carNumber,
        carColor: data.carColor,
      };
      dispatch(updateUserProfileInfo(carInfoDetails, user?.id));
      enqueueSnackbar("Car information updated successfully", {
        variant: "success",
      });
    } catch (error) {
      reset();
      enqueueSnackbar("Error submitting form", { variant: "error" });
    }
  };

  return (
    <Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} mt={4}>
          <RHFTextField
            name="carNumber"
            label="Car Number"
            control={control}
            fullWidth
            variant="outlined"
          />

          <FormControl fullWidth error={!!errors.carColor}>
            <InputLabel id="carColor">Car Color</InputLabel>
            <Controller
              name="carColor"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="carColor"
                  value={field.value}
                  onChange={field.onChange}
                  label="Car Color"
                >
                  {carColors.map((color) => (
                    <MenuItem key={color} value={color}>
                      {color}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.carColor && (
              <Typography
                sx={{ fontSize: "12px", margin: "8px 14px 0px" }}
                color="error"
              >
                {errors.carColor.message}
              </Typography>
            )}
          </FormControl>
        </Stack>

        <Stack sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <LoadingButton
            color="inherit"
            type="submit"
            variant="contained"
            sx={{
              width: "20%",
              marginTop: "15px",
              bgcolor: "#FCA92E",
              color: (theme) =>
                theme.palette.mode === "light" ? "#000000" : "grey.800",
              "&:hover": {
                bgcolor: "text.primary",
                color: (theme) =>
                  theme.palette.mode === "light" ? "common.white" : "grey.800",
              },
            }}
          >
            Update
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Box>
  );
}
