import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Tab, Tabs } from "@mui/material";
import Image from "src/components/image/Image";
import { useSelector, useDispatch } from "react-redux";
import SwitchOrderTypeModal from "src/pages/switchOrderTypeModal/SwitchOrderTypeModal";
import useSession from "src/utils/useSession";
import { selectedVenue } from "src/utils/venueSelectionUtils";
import { clearTableData } from "src/redux/slices/tableSlice";

export function MUIButtonsPage({ TABS }) {
  const [currentTab, setCurrentTab] = useState("");
  const [fixedTabs, setFixedTabs] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = TABS?.map((menu) => {
        const section = document.getElementById(menu.name);
        return {
          title: menu.name,
          offset: section ? section.getBoundingClientRect().top : 0,
        };
      });
      const inViewSection = sectionOffsets?.find(
        (offset) => offset.offset > 0 && offset.offset < 190
      );
      if (inViewSection) {
        setCurrentTab(inViewSection.title);
      }
      if (window.scrollY > 190) {
        setFixedTabs(true);
      } else {
        setFixedTabs(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [TABS]);

  const handleTitleClick = (name) => {
    const section = document.getElementById(name);
    if (section) {
      const yOffset = -200;
      const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      setCurrentTab(name);
    }
  };

  return (
    <>
      <Helmet>
        <title>Egora</title>
      </Helmet>
      <Box
        sx={{
          paddingTop: "20px",
          "@media (max-width: 600px)": {
            paddingTop: "32px",
          },
        }}
        
      >
        <Box
          sx={{
            position: fixedTabs ? "fixed" : "static",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            background: "#FEF5E4",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            padding: "8px",
            "@media (max-width: 1440px)": {
              top: 72,
            },
            "@media (max-width: 900px)": {
              top: 64,
            },
            "@media (max-width: 600px)": {
              top: 94,
            },
          }}
        >
          <Tabs
            value={currentTab}
            onChange={(events, newValue) => setCurrentTab(newValue)}
            variant="scrollable"
            scrollButtons="off"
          >
            {TABS.map((tab) => (
              <Tab
                value={tab.name}
                label={tab.name}
                key={tab.id}
                id={`${tab.name}-title`}
                className="menu-title"
                onClick={() => handleTitleClick(tab.name)}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </>
  );
}

export function HeaderTabs() {
  const [openModal, setOpenModal] = useState(false);
  const sessionInfo = useSession();
  const handleClose = () => {
    setOpenModal(false);
  };
  let orderType = localStorage.getItem("mode");
  if (orderType == "QrDineIn") {
    orderType = "dineIn";
  } else if (orderType == "QrPickUp") {
    orderType = "pickUp";
  }

  const [currentTab, setCurrentTab] = useState(orderType);
  let address = localStorage.getItem("address");
  let dispatch = useDispatch();
  const { tableData } = useSelector((state) => state.tableData);
  const { cardItems, isPickUp, isDineIn } = useSelector(
    (state) => state.cardSlice
  );

  const handleTabChange = async (newValue) => {
    if (
      cardItems?.length === 0 ||
      cardItems?.data === "" ||
      cardItems?.data?.items.length === 0
    ) {
      if (newValue === "dineIn") {
        setCurrentTab(newValue);
        await selectedVenue(dispatch, "isDineIn", true);
      } else if (newValue === "pickUp") {
        if(tableData){
          dispatch(clearTableData())
        }
        setCurrentTab(newValue);
        await selectedVenue(dispatch, "isPickUp", true);
      }
    } else if (cardItems?.data?.items?.length > 0 && tableData.length === 0) {
      if (isDineIn === true && isPickUp === true) {
        if (newValue === "dineIn") {
          setCurrentTab(newValue);
          await selectedVenue(dispatch, "isDineIn", true);
        } else if (newValue === "pickUp") {
          setCurrentTab(newValue);
          await selectedVenue(dispatch, "isPickUp", true);
        }
      } else if (isDineIn === true && isPickUp === false) {
        if (newValue === "dineIn") {
          setCurrentTab(newValue);
          await selectedVenue(dispatch, "isDineIn", true);
        } else if (newValue === "pickUp") {
          setOpenModal(true);
        }
      } else if (isDineIn === false && isPickUp === true) {
        if (newValue === "dineIn") {
          setOpenModal(true);
        } else if (newValue === "pickUp") {
          setCurrentTab(newValue);
          await selectedVenue(dispatch, "isPickUp", true);
        }
      }
    } else if (cardItems?.data?.items?.length > 0 && tableData) {
      if (newValue === "dineIn") {
        setCurrentTab(newValue);
        await selectedVenue(dispatch, "isDineIn", true);
      } else if (newValue === "pickUp") {
        setOpenModal(true);
      }
    } else {
      console.log("elseee");
    }
  };

  const TABS = [
    {
      name: "dineIn",
      img: "/assets/header-tabs/dine-in-orange1.svg",
      imgblack: "/assets/header-tabs/Dine-in-black.svg",
    },
    {
      name: "pickUp",
      img: "/assets/header-tabs/Pickup-orange.svg",
      imgblack: "/assets/header-tabs/Pickup-1.svg",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Egora</title>
      </Helmet>

      <Box
        sx={{
          zIndex: 999,
          background: "transparent",
        }}
      >
        <Tabs
          value={currentTab}
          TabIndicatorProps={{
            style: {
              height: 4,
              transition: "left 0.3s ease-in-out",
            },
          }}
          onChange={(event, newValue) => handleTabChange(newValue)}
          variant="scrollable"
          scrollButtons="off"
          sx={{
            display: "flex",
            flexDirection: "row",
            "@media (max-width: 600px)": {
              flexDirection: "row",
              justifyContent: "space-between",
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab
              value={tab.name}
              sx={{
                margin: "10px 0px",
                fontWeight: "bold",
                width: "7%",
                "&.Mui-selected": {
                  color: "#FFA500",
                },
                "@media (max-width: 769px)": {
                  width: "10%",
                },

                "@media (max-width: 600px)": {
                  flex: "1 1 50%",
                },
              }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src={currentTab === tab.name ? tab.img : tab.imgblack}
                    alt={tab.name}
                    sx={{
                      marginRight: "8px",
                      width: "40px",
                      "@media (max-width: 1025px)": {
                        width: "30px",
                      },
                      "@media (max-width: 420px)": {
                        marginRight: tab.name === "dineIn" ? "auto" : "0",
                        marginLeft: tab.name === "pickUp" ? "auto" : "0",
                      },
                    }}
                  />
                  {tab.name}
                </Box>
              }
              key={tab.name}
            />
          ))}
        </Tabs>
      </Box>

      {openModal && (
        <SwitchOrderTypeModal
          width="sm"
          open={openModal}
          onClose={handleClose}
          selectedVenue={selectedVenue}
          setCurrentTab={setCurrentTab}
        />
      )}
    </>
  );
}
