import axios from "axios";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "src/redux/slices/loginSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import {
  Stack,
  Alert,
  Box,
  InputAdornment,
  Divider,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormProvider, { RHFTextField } from "../../../../components/hook-form";
import { useSnackbar } from "../../../../components/snackbar";
import { useSelector } from "react-redux";
import { PATH_AUTH } from "src/routes/paths";

const CountryCode = process.env.REACT_APP_COUNTRY_CODE;

export default function GuestModalContent({ setToggle }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.login);

  const { cardItems } = useSelector((state) => state.cardSlice);

  const [errorMsg, setErrorMsg] = useState("");

  const LoginSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z ]*$/, "This field contains only alphabets"),
    phone: Yup.string()
      .required("Contact number is required")
      .matches(/^\d*$/, "This field contains only numbers")
      .test(
        "len",
        "This field contains 10 digits",
        (val) => val && val.length === 10
      ),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2})\]?$)/,
        "Invalid Email Format"
      ),
  });

  const defaultValues = {
    name: cardItems?.data?.customer?.name
      ? cardItems.data.customer.name
      : user?.name
      ? user?.name
      : "",
    phone: cardItems?.data?.customer?.phone
      ? cardItems.data.customer.phone
      : user?.phone
      ? user?.phone
      : "",
    email: cardItems.data?.customer?.email ?? "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const userData = {
        name: data.name,
        phone: data.phone,
        email: data.email,
      };

      const cartId = cardItems?.data?.id;
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/cart/${cartId}`, {
        customer: userData,
      });
      navigate("/checkout");
    } catch (error) {
      reset();
      setError("afterSubmit", {
        ...error,
        message: error?.response?.data?.message || "An error occurred",
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ mt: 2 }} spacing={2}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}
        <RHFTextField name="name" label="Name" />
        <RHFTextField
          sx={{ borderRadius: "transparent" }}
          name="phone"
          label="Phone number"
          placeholder="3xxxxxxxxx"
          error={Boolean(errors.phone) || Boolean(errorMsg)}
          helperText={errors.phone ? errors.phone.message : errorMsg || ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span>{CountryCode}</span>
              </InputAdornment>
            ),
            inputMode: "numeric",
          }}
          inputProps={{
            maxLength: 10,
            onInput: (e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
              setErrorMsg("");
            },
            onKeyDown: (e) => {
              if (
                !/^[0-9]$/.test(e.key) &&
                e.key !== "Backspace" &&
                e.key !== "Tab"
              ) {
                e.preventDefault();
              }
            },
          }}
        />
        <RHFTextField name="email" label="Email address" />
      </Stack>
      <Box mt={2}>
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          sx={{
            bgcolor: "#FCA92E",
            color: (theme) =>
              theme.palette.mode === "light" ? "#000000" : "grey.800",
            "&:hover": {
              bgcolor: "text.primary",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          Continue
        </LoadingButton>
      </Box>

      <Divider
        sx={{
          my: 2.5,
          typography: "overline",
          color: "text.disabled",
        }}
      >
        or
      </Divider>
      <Stack
        direction="row"
        spacing={0.5}
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Typography variant="body2">Already have an account?</Typography>

        <Link
          variant="subtitle2"
          onClick={() => setToggle(false)}
          style={{
            color: "#FCA92E",
            textDecoration: "none",
            fontWeight: "bold",
          }}
        >
          Login
        </Link>
      </Stack>
    </FormProvider>
  );
}
