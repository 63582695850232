import { useState } from "react";
import {
  Stack,
  Box,
  IconButton,
  Typography,
  Button,
  DialogTitle, DialogContent, DialogActions, Divider
} from "@mui/material";
import { useSnackbar } from "src/components/snackbar";
import { useSelector, useDispatch } from "react-redux";
import { updateUserCardsInformation } from "src/redux/slices/loginSlice";
import api from "src/utils/axios";
import Iconify from "src/components/iconify/Iconify";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";

export default function UserSaveCardInfo() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.login);
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleOpenConfirm = (id) => {
    setSelectedCardId(id);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setSelectedCardId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await api.delete(
        `${process.env.REACT_APP_BACKEND_URL}/payment/deleteToken?tokenId=${selectedCardId}&userId=${user.id}`
      );      
      if (response?.data) {
        await dispatch(updateUserCardsInformation(response.data.customer?.savedCardsInformation));
        enqueueSnackbar("Card deleted successfully", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar("Error in deleting the card!", { variant: "error" });
    } finally {
      handleCloseConfirm();
    }
  };


  return (
    <>
      <Box>
        <Stack spacing={2} mt={4}>
          <Typography variant="h5" mb={2}>
            Card Information
          </Typography>
          <Stack alignItems="flex-start" sx={{ width: 1 }}>
            {user?.savedCardsInformation?.length > 0 ? (
              user?.savedCardsInformation?.map((card, index) => (
                <Box
                  key={card.value}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  mb={2}
                  p={2}
                  border="1px solid #ccc"
                  borderRadius="8px"
                >
                  <Typography>
                    **** **** **** {card.lastDigits}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    color="error"
                    onClick={() => handleOpenConfirm(card._id)}
                  >
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Box>
              ))
            ) : (
              <Typography>No saved cards available.</Typography>
            )}
          </Stack>
        </Stack>
      </Box>

      <ConfirmDialog
        width="sm"
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
      >
        <DialogTitle>
          <Typography
            variant="h6"
            sx={{
              fontWeight: "700",
              fontSize: "25px",
            }}
          >
            Delete
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ typography: "body2" }}>
          <Typography >
            Are you sure you want to delete this card ?
          </Typography>
          <Box
            mt={3}
            mb={4}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button
              onClick={handleConfirmDelete}
              type="button"
              variant="contained"
              color="primary"
              sx={{
                fontWeight: '600',
                height: "40px",
                fontSize: "16px",
              }}
            >
              Delete
            </Button>
            <Button
              onClick={handleCloseConfirm}
              type="button"
              variant="outlined"
              color="primary"
              sx={{
                fontWeight: '600',
                height: "40px",
                fontSize: "16px",
              }}
            >
              Cancel

            </Button>
          </Box>
        </DialogContent>


      </ConfirmDialog>
    </>
  );
}
