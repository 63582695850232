import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Paper,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Iconify from "../../components/iconify";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import PaymentNewCardComponent from "./PaymentNewCardDialog";
import CartItemModal from "src/pages/Venue/cartModal/CartItemModal";
import { clearTableData } from "src/redux/slices/tableSlice";
import { useNavigate } from "react-router-dom";
import { selectedVenue } from "src/utils/venueSelectionUtils";
const PAYMENT_OPTIONS = [
  {
    value: "cash",
    title: "Cash",
    icons: ["/assets/icons/payments/ic_paypal.svg"],
  },
  {
    value: "card",
    title: "Credit / Debit Card",
    icons: [
      "/assets/icons/payments/ic_mastercard.svg",
      "/assets/icons/payments/ic_visa.svg",
    ],
  },
];

export default function PaymentMethods({
  openCard,
  setOpenCard,
  method,
  setMethod,
  cashAvailable,
  serviceFee,
  taxAmount,
  total,
  setError,
  error,
  setIsDisabled
}) {
  const { cardItems } = useSelector((state) => state.cardSlice);
  const { tableData } = useSelector((state) => state.tableData);
  const [openCart, setOpenCart] = useState(false);

  const navigate = useNavigate();

  let dispatch = useDispatch();

  const handleClose = () => {
    setOpenCart(false);
  };

  const handleChangeMethod = async (event) => {
    const getMode = localStorage.getItem("mode");
    setMethod(event.target.value);
    if(error == "Table is Not Available"){
      setOpenCart(true)
    }else{
      setError("")
    }

    if (event.target.value === "card") {
      let orderType = localStorage.getItem("mode");
      const checkVenueStatus = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/venues/b2c/${cardItems?.data?.venueId}`)
      if (checkVenueStatus.data.status == "inActive") {
        await selectedVenue(dispatch, orderType, true);
        localStorage.setItem("VenueNotAvailableMessageSeen", false);
        setError("Venue is inActive, You cant place Order")
        const errorMessage = 'Venue Not Available'
        navigate(`/venue`, { replace: true, state: { errorMessage } });

      } else {
        let table = null
        if(tableData?.id &&  (orderType == "QrDineIn" || orderType == "dineIn") ){
          table = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/tables/${tableData.id}`)
        }
        if ( (table?.data?.isAvailable && table)|| (orderType != "QrDineIn" && orderType != "dineIn")) {
          setOpenCard(true);
        } else if(table && table?.data?.isAvailable){
          dispatch(clearTableData())
          setError("Table is Not Available")
          setOpenCart(true)
        }else{
          setOpenCard(true);
        }
      }
    } else {
      setOpenCard(false);
    }
  };

  return (
    <>
      <RadioGroup
        value={method}
        onChange={handleChangeMethod}
        sx={{ marginTop: 0 }}
      >
        <Stack spacing={3}>
          {PAYMENT_OPTIONS.filter((option) => {
            if (option.value === "cash" && !cashAvailable) {
              return false;
            }
            return true;
          }).map((option) => (
            <PaymentOption
              key={option.title}
              option={option}
              isSelected={method === option.value}
              hasChild={option.value === "card"}
              isCreditMethod={option.value === "card" && method === "card"}
            />
          ))}
        </Stack>
      </RadioGroup>

      {method === "card" && openCard && (
        <PaymentNewCardComponent
          error={error}
          setError={setError}
          method={method}
          total={total}
          serviceFee={serviceFee}
          taxAmount={taxAmount}
          setIsDisabled={setIsDisabled}
          setOpenCart={setOpenCart}
        />
      )}

      {openCart && cardItems?.data?.items?.length > 0 && (
        <CartItemModal width="md" open={openCart} onClose={handleClose} errorMessage={error} />
      )}
    </>
  );
}

// ----------------------------------------------------------------------

PaymentOption.propTypes = {
  onOpen: PropTypes.func,
  hasChild: PropTypes.bool,
  option: PropTypes.object,
  isSelected: PropTypes.bool,
  isCreditMethod: PropTypes.bool,
};

function PaymentOption({
  option,
  hasChild,
}) {
  const { value, title, icons } = option;

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        transition: (theme) => theme.transitions.create("all"),
        ...(hasChild && {
          flexWrap: "wrap",
        }),
      }}
    >
      <FormControlLabel
        value={value}
        control={
          <Radio checkedIcon={<Iconify icon="eva:checkmark-circle-2-fill" />} />
        }
        label={title}
        sx={{ py: 2, pl: 2.5, flexGrow: 1, mr: 0 }}
      />

      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ position: "absolute", right: 20, top: 24 }}
      >
        {/* {icons.map((icon) => (
          <Box component="img" key={icon} src={icon} />
        ))} */}
      </Stack>
    </Paper>
  );
}
