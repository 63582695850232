import { Helmet } from "react-helmet-async";
import VenueHeader from "../VenueHeader";
import FooterLogin from "src/layouts/compact/FooterLogin";
import ActiveOrder from "./ActiveOrder";
import { Box } from "@mui/material";

const ActiveOrderPage = () => {
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    }}
  >

      <Helmet>
        <title>Egora-Order</title>
      </Helmet>
      <VenueHeader />
      <ActiveOrder />
      <FooterLogin />
    </Box>
  );
};
export default ActiveOrderPage;
