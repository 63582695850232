import {
    Stack,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    useMediaQuery,
    useTheme,
    Button,
    IconButton
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";

export default function LocationEnableModal({ open, onClose, width }) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "25px",
                    }}
                >
                    Enable Location
                </Typography>

                <IconButton aria-label="close" onClick={onClose}>
                    <Iconify
                        sx={{
                            height: 25,
                            width: 25,
                        }}
                        icon="mdi:close"
                        color="#fca92e"
                    />
                </IconButton>
            </DialogTitle>

            <Box>
                <Divider />
            </Box>

            <DialogContent sx={{ typography: "body2", padding: "24px" }}>
                <Stack spacing={2}>

                    <Typography variant="body1" color="text.primary">
                        <strong>On your Chrome browser:</strong>
                    </Typography>

                    {isMobile ? (
                        <>
                            <Typography variant="body2" color="text.secondary">
                                1. On the left of the address bar, click the  <Iconify sx={{  height: 25, width: 25, verticalAlign: 'middle' }} icon="uit:exclamation-circle" color="#fca92e" /> icon.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                2. From there Click on the permissions
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                3. Now either turn the location on directly or reset the permissions
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                4. Reload the page once
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="body2" color="text.secondary">
                                1. On the left of the address bar, click the  <Iconify sx={{  height: 20, width: 20, verticalAlign: 'middle' }} icon="uit:exclamation-circle" color="#fca92e" />  icon.
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                2. From the location, reset the permission or either turn it on directly
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                3. Reload the page once
                            </Typography>
                        </>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>

    );
}
