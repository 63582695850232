import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, AppBar, Toolbar, useMediaQuery, Container } from "@mui/material";
import useOffSetTop from "../../hooks/useOffSetTop";
import { bgBlur } from "../../utils/cssStyles";
import { HEADER } from "../../config-global";
import Logo from "../../components/logo";
import HeaderSearchBar from "./header/HeaderSearchBar";
import CartItemModal from "./cartModal/CartItemModal";
import { useDispatch, useSelector } from "react-redux";
import HeaderAuthButton from "./header/HeaderAuthButton";
import { useLocation } from "react-router-dom";
import VenueLocation from "./header/VenueLocation";
import HeaderCart from "./header/HeaderCart";
import { HeaderTabs } from "../components/mui/MUIButtonsPage";
import CarInformationModal from "./CarInformation/CarInformationModal";
import LocationPermissionModal from "./LocationEnable/LocationEnableModal";
import VenueFarAwayModal from "./LocationEnable/VenueFarAwayModal";
import { closeVenueLocationModal, closeVenueFarAwayModal } from 'src/redux/slices/VenueLocationModalSlice';

const VenueHeader = () => {
  const dispatch = useDispatch();

  const [openCart, setOpenCart] = useState(false);
  const [openCarInformationModal, setOpenCarInformationModal] = useState(false);
  const { user } = useSelector((state) => state.login);

  const { openModal, openVenueFarAwayModal } = useSelector((state) => state.venueLocationModal); 
  const handleCloseModal = () => dispatch(closeVenueLocationModal());
  const handleCloseVenueFarAwayModal = () => dispatch(closeVenueFarAwayModal());

  const theme = useTheme();
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const { cardItems } = useSelector((state) => state.cardSlice);
  const location = useLocation();

  const handleClose = () => {
    setOpenCart(false);
  };

  const handleCloseCarInformationModal = () => {
    setOpenCarInformationModal(false);
  };

  return (
    <>
      <AppBar
        color="transparent"
        sx={{
          width: "100%",
          position: "fixed",
          zIndex: 1000,
          background: "#FFFFFF",
          boxShadow: "0px 4px 3px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box
          sx={{
            marginLeft: "35px",
            marginRight: "35px",
            "@media (max-width: 1025px)": {
              marginLeft: "0px",
              marginRight: "0px",
            },
          }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                display: { xs: "flex", sm: "none" },
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Logo />
            </Box>

            <Toolbar
              disableGutters
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                height: {
                  xs: HEADER.H_MOBILE,
                  md: HEADER.H_MAIN_DESKTOP,
                },
                transition: theme.transitions.create(
                  ["height", "background-color"],
                  {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.shorter,
                  }
                ),
                ...(isOffset && {
                  ...bgBlur({ color: theme.palette.background.default }),
                  height: {
                    md: HEADER.H_MAIN_DESKTOP - 16,
                  },
                }),
              }}
            >
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Logo sx={{ mt: 2 }} />
              </Box>
              {/* <VenueLocation /> */}
              <HeaderSearchBar />

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  "@media (max-width: 890px)": {
                    gap: "0px",
                  },
                }}
              >
                <Box>
                  <HeaderCart openCart={openCart} setOpenCart={setOpenCart} />
                </Box>
                <Box>
                  <HeaderAuthButton />
                </Box>
              </Box>
            </Toolbar>
            {isOffset && <Shadow />}

            {location?.pathname === "/venue" && <HeaderTabs />}
          </Container>
        </Box>
      </AppBar>
      {openCart && cardItems?.data?.items?.length > 0 && (
        <CartItemModal
          width="md"
          open={openCart}
          onClose={handleClose}
          setOpenCarInformationModal={setOpenCarInformationModal}
        />
      )}
      {openCarInformationModal && (
        <CarInformationModal
          width="md"
          open={openCarInformationModal}
          onClose={handleCloseCarInformationModal}
          user={user}
        />
      )}

      {
        <LocationPermissionModal 
        width="md"
        open={openModal}
        onClose={handleCloseModal}
        />
      }

      {
        <VenueFarAwayModal 
        width="md"
        open={openVenueFarAwayModal}
        onClose={handleCloseVenueFarAwayModal}
        />
      }
    </>
  );
};

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      component="div"
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: "auto",
        borderRadius: "50%",
        position: "absolute",
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}

export default VenueHeader;
