import { Box, Grid, Stack, Typography, Container } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { fetchImage } from "src/utils/fetchImage";
import { useSelector, useDispatch } from "react-redux";
import { getActiveOrders } from "src/redux/slices/orderPlaceSlice";
import useSession from "src/utils/useSession";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import EmptyContent from "src/components/empty-content/EmptyContent";
import ProcessingModal from "./processModdal/ProcessingModal";
import { socket } from "src/App";
import OrderCustomizedSteppers from "./OrderCustomizedStepper";
import Label from "src/components/label";
import OrderStatusModal from "./OrderStatusModal/OrderStatusModal";

const ActiveOrder = () => {
  const sessionInfo = useSession();
  const { order, isLoading } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.login);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [orderStatusModal, setOrderStatusModal] = useState({
    openModal:false,
    detail:''
  });


  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const handleCloseStatusModal = () => {
    setOrderStatusModal({openModal:false});
  };

  const dispatch = useDispatch();
  const getOrder = useCallback(async () => {
    try {
      await dispatch(getActiveOrders(sessionInfo?.sessionId, user?.id));
    } catch (error) {
      console.log("error", error);
    }
  }, [order]);
  const [detail, setDetail] = useState("");

  const [imageURLs, setImageURLs] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const ItemDetails = (elem) => {
    setDetail(elem);
    setOpenConfirm(true);
  };

  useEffect(() => {
    if (order && order.length > 0) {
      const filtered = order.filter((item) =>
        ["pending", "processing", "finished", "completed"].includes(item.state)
      );
      setFilteredOrders(filtered);
    }
  }, [order]);

  const hasFilteredOrders = filteredOrders && filteredOrders.length > 0;

  useEffect(() => {
    const fetchImagesForOrders = async () => {
      if (filteredOrders && filteredOrders.length > 0) {
        const urls = await Promise.all(
          filteredOrders.map(async (elem) => {
            try {
              const res = await fetchImage(elem.venueId.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForOrders();
  }, [hasFilteredOrders]);

  const handleOrderCancelSuccess = (orderId) => {
    setFilteredOrders((prevOrders) =>
      prevOrders.filter((order) => order._id !== orderId)
    );
  };

  useEffect(() => {
    const hasStateChanged =
      filteredOrders &&
      filteredOrders.length > 0 &&
      filteredOrders.some((item) =>
        ["pending", "processing", "finished", "completed"].includes(item.state)
      );
    if (hasStateChanged) {
      getOrder();
    }
  }, [hasFilteredOrders]);

  useEffect(() => {
    socket.on("changeState", async (data) => {
      setFilteredOrders((prevFiltredOrders) => {
        if (prevFiltredOrders) {
          const objectFound = prevFiltredOrders.find(
            (obj) => obj._id === data.id
          );
          if (objectFound) {
            const filteredOrder = prevFiltredOrders.filter(
              (order) => order._id !== data.id
            );
            if (
              (data.state == "completed" && data.isPaid == true) ||
              data.state == "abort" ||
              data.state == "noShow"
            ) {
              setOpenConfirm(false);
              console.log('abort',data?.state==="abort");
              
              setOrderStatusModal({openModal:true,detail:data})
              return [...filteredOrder];
            } else {
              console.log('processing',data);


              return [...filteredOrder, data];
            }
          } else {
            return prevFiltredOrders;
          }
        } else {
          return prevFiltredOrders;
        }
      });
    });
  }, [socket]);

  useEffect(() => {
    if (openConfirm && detail) {
      const updatedDetail = filteredOrders.find(
        (item) => item._id === detail._id
      );
      if (updatedDetail) {
        setDetail(updatedDetail);
      } else {
        handleCloseConfirm();
      }
    }
  }, [filteredOrders, openConfirm, detail]);

  return (
    <>
      <Box
        sx={{
          marginLeft: "35px",
          marginRight: "35px",
          "@media (max-width: 1025px)": {
            marginLeft: "0px",
            marginRight: "0px",
          },
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              marginTop: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              overflow: "hidden",
              "@media (max-width: 899px)": {
                marginTop: "150px",
              },

              "@media (max-width: 500px)": {
                marginTop: "100px",
              },
            }}
          >
            <Stack>
              <Typography variant="h3">Active Orders</Typography>
            </Stack>
          </Box>
          {isLoading ? (
            <LoadingScreen width="100px" height="100px" marginT="0" h="70vh" />
          ) : (
            <>
              {hasFilteredOrders ? (
                <Box>
                  {filteredOrders?.length > 0 ? (
                    <Grid container spacing={3} mt={1} mb={5}>
                      {filteredOrders &&
                        filteredOrders?.map((elem, index) => {
                          return (
                            <Grid item xs={12} md={6} key={index}>
                              <Box
                                onClick={() => ItemDetails(elem)}
                                sx={{
                                  flexDirection: "column",
                                  padding: "20px",
                                  border: "1px solid rgba(0, 0, 0, 0.15)",
                                  borderRadius: "8px",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                  cursor: "pointer",
                                  backgroundColor: "#fff",
                                }}
                              >
                                <Stack
                                  sx={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {elem?.venueId?.name}{" "}
                                  </Typography>

                                  <Label
                                    variant="soft"
                                    sx={{
                                      textTransform: "capitalize",
                                      backgroundColor: "#FFF2E4",
                                      height: "30px",
                                      color: "#F08203",
                                    }}
                                  >
                                    {elem?.orderType === "pickUp"
                                      ? "PickUp"
                                      : elem?.orderType === "QrPickUp"
                                        ? "QR PickUp"
                                        : elem?.orderType === "dineIn"
                                          ? "DineIn"
                                          : elem?.orderType === "QrDineIn"
                                            ? "QR DineIn"
                                            : ""}
                                  </Label>
                                </Stack>
                                <Stack spacing={2}>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    paddingLeft="10px"
                                    paddingRight="10px"
                                  >
                                    <Box
                                      component="img"
                                      src="/assets/order/timer.svg"
                                      alt="Order status"
                                      sx={{ width: 16, height: 16 }}
                                    />

                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                      }}
                                    >
                                      {elem?.state === "processing" ? (
                                        <>Your food is being prepared.</>
                                      ) : elem?.state === "pending" ? (
                                        "Your order is pending for confirmation."
                                      ) : elem?.state === "finished" ? (
                                        elem?.orderType === "pickUp" ||
                                          elem?.orderType === "QrPickUp" ? (
                                          "Your order is ready for pickup."
                                        ) : elem?.orderType === "dineIn" ||
                                          elem?.orderType === "QrDineIn" ? (
                                          "Your order is ready for serving."
                                        ) : (
                                          "Your order is completed."
                                        )
                                      ) : elem?.state === "completed" ? (
                                        "Your order is completed"
                                      ) : (
                                        ""
                                      )}
                                    </Typography>
                                  </Stack>

                                  <Box mt={2}>
                                    <OrderCustomizedSteppers
                                      orderStatus={elem.state}
                                    />
                                  </Box>
                                </Stack>
                              </Box>
                            </Grid>
                          );
                        })}
                    </Grid>
                  ) : (
                    <EmptyContent
                      title="No Orders"
                      sx={{
                        "& span.MuiBox-root": { height: 160 },
                      }}
                    />
                  )}
                </Box>
              ) : (
                <EmptyContent
                  title="No Active Orders"
                  sx={{
                    "& span.MuiBox-root": { height: 160 },
                  }}
                />
              )}
            </>
          )}
        </Container>
      </Box>

      {openConfirm && (
        <ProcessingModal
          width="sm"
          open={openConfirm}
          onClose={handleCloseConfirm}
          detail={detail}
          onCancelSuccess={handleOrderCancelSuccess}
        />
      )}
      {orderStatusModal?.openModal && (
        <OrderStatusModal
          width="sm"
          orderStatusModal={orderStatusModal}
          onClose={handleCloseStatusModal}
        />
      )}
    </>
  );
};
export default ActiveOrder;
