import { Helmet } from "react-helmet-async";
import VenueHeader from "../VenueHeader";
import FooterLogin from "src/layouts/compact/FooterLogin";
import CartCheckoutSummary from "./CartCheckoutSummary";
import { Box } from "@mui/material";

const CartCheckout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Egora-Checkout</title>
      </Helmet>

      <VenueHeader />

      <Box sx={{ flex: "1 0 auto" }}>
        <CartCheckoutSummary />
      </Box>

      <FooterLogin />
    </Box>
  );
};

export default CartCheckout;
