import { useState, useEffect } from "react";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stack,
  Alert,
  TextField,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FormProvider, { RHFTextField } from "../../../components/hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../components/snackbar";
import { getProfile, updateUserProfileInfo } from "src/redux/slices/loginSlice";

export default function UpdateUserProfileInfo() {
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [dob, setDOB] = useState(null);
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First name required")
      .matches(/^[a-zA-Z ]*$/, "This field contains only alphabets"),
    lastName: Yup.string()
      .required("Last name required")
      .matches(/^[a-zA-Z ]*$/, "This field contains only alphabets"),
    dob: Yup.date()
      .nullable()
      .transform((value, originalValue) => {
        const parsedDate = Date.parse(originalValue);
        return !isNaN(parsedDate) ? new Date(parsedDate) : null;
      })
      .max(new Date(), "Date can't be in the future")
      .required("Date of birth is required"),
  });

  const defaultValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    dob: user?.dob || new Date(),
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (!user?.id) {
      navigate("/venue");
    }
  }, [user?.id]);

  useEffect(() => {
    if (user?.id) {
      dispatch(getProfile(user?.id));
    }
  }, [dispatch, user?.id]);

  const onChangeDOB = (newValue) => {
    setDOB(newValue);
    setValue("dob", newValue, { shouldValidate: true });
  };
  useEffect(() => {
    if (user) {
      const userDOB = user?.dob ? new Date(user.dob) : null;
      setDOB(userDOB);
      reset({
        ...defaultValues,
        dob: userDOB,
      });
    }
  }, [user]);

  const onSubmit = async (data) => {
    try {
      const response = await dispatch(updateUserProfileInfo(data, user?.id));
      if (response.status === 200) {
        enqueueSnackbar("Profile updated successfully");
      }
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(`${error?.response?.data?.message}`, {
        variant: "error",
      });
      reset();
      setError("afterSubmit", {
        ...error,
        message: error?.response?.data?.message,
      });
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h3" mb={2}>
          Profile
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <RHFTextField name="firstName" label="Enter your First name" />
            <RHFTextField name="lastName" label="Enter your Last name" />

            <Controller
              name="dob"
              control={control}
              maxDate={new Date()}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  label="Date of Birth"
                  inputFormat="dd/MM/yyyy"
                  value={dob}
                  onChange={onChangeDOB}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <LoadingButton
              color="inherit"
              type="submit"
              variant="contained"
              sx={{
                width: "20%",
                marginTop: "15px",
                bgcolor: "#FCA92E",
                color: (theme) =>
                  theme.palette.mode === "light" ? "#000000" : "grey.800",
                "&:hover": {
                  bgcolor: "text.primary",
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "common.white"
                      : "grey.800",
                },
              }}
            >
              Update
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Box>
    </>
  );
}
