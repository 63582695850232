import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Link,
  Stack,
  Divider,
  Typography,
  IconButton,
  Button,
  Container,
} from "@mui/material";
import { _socials } from "../../_mock/arrays";
import Logo from "../../components/logo";
import Iconify from "../../components/iconify";
import googlePlayIcon from "../../assets/icons/google_play.svg";
import appleWIcon from "../../assets/icons/app_store.svg";
import Image from "src/components/image/Image";

const LINKS = [
  {
    headline: "Minimal",
    children: [
      { name: "Help Center", href: "" },
      { name: "About Us", href: "" },
      { name: "Careers", href: "" },
      { name: "Contact us", href: "" },
    ],
  },
];

const FooterLogin = () => {
  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        mt: "auto",
      }}
    >
      <Divider />
      <Container maxWidth="xl">
        <Stack
          justifyContent="space-between"
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "center", md: "flex-start" }}
          sx={{
            marginTop: 5,
            marginLeft: 2.5,
            marginBottom: 5,

            "@media (max-width: 890px)": {
              marginLeft: 0,
            },
          }}
        >
          <Stack key="column-3" spacing={1} flexGrow={8}>
            <div>
              {_socials.map((social) => (
                <a
                  key={social.name}
                  href={social.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <IconButton>
                    <Iconify icon={social.icon} />
                  </IconButton>
                </a>
              ))}
            </div>
          </Stack>
          <Stack key="column-4" spacing={1} flexGrow={0.3}>
            <div>
              <Typography
                variant="caption"
                component="div"
                sx={{
                  pb: 5,
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                © Copyrights 2024 egora All rights reserved
              </Typography>
            </div>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default FooterLogin;
