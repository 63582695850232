import { Box, Typography, Button } from "@mui/material";

const Completed = ({ detail, toggleContent, setToggleContent }) => {
  return (
    <Box>
      <Typography sx={{ margin: "0px" }}>
        We hope you enjoy your meal!
      </Typography>

      <Box mt={2} mb={4}>
        <Button
          onClick={() => setToggleContent(!toggleContent)}
          type="button"
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            height: "40px",
            fontSize: "16px",
          }}
        >
          {toggleContent ? "Hide order details" : "show order details"}
        </Button>
      </Box>
    </Box>
  );
};
export default Completed;
