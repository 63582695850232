import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import VenueHeader from "./VenueHeader";
import VenueCuisines from "./VenueCuisines";
import FooterLogin from "src/layouts/compact/FooterLogin";
import { useSelector } from "react-redux";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import VenueNotAvailableModal from "./VenueNotAvailable/VenueNotAvailableModal";
import {  useLocation } from "react-router-dom";

const VenueMainPage = () => {
  const { isLoading } = useSelector((state) => state.venues);
  const {  state } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(state?.errorMessage || null);
  const handleClose = () => {
    setOpenModal(false);
    setErrorMessage(null)
  };
  useEffect(() => {
    const message = localStorage.getItem("VenueNotAvailableMessageSeen")
    if(errorMessage &&  (errorMessage == 'Venue Not Available') && (message == 'false')){
      localStorage.setItem("VenueNotAvailableMessageSeen", true);
      setOpenModal(true)
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Egora-Venues</title>
      </Helmet>
      <VenueHeader />
      { errorMessage &&
     
        <VenueNotAvailableModal width="md" open={openModal} onClose={handleClose}/>
      }
      
      {isLoading ? (
        <LoadingScreen width="100px" height="100px" marginT="0" h="100vh" />
      ) : (
        <VenueCuisines />
      )}
      <FooterLogin />
    </div>
  );
};

export default VenueMainPage;
