import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openModal: false, 
  openVenueFarAwayModal: false,
  venueIdToBlock: null
};

const venueLocationModalSlice = createSlice({
  name: 'venueLocationModal',
  initialState,
  reducers: {
    openVenueLocationModal(state) {
      state.openModal = true; 
    },
    closeVenueLocationModal(state) {
      state.openModal = false; 
    },
    openVenueFarAwayModal(state, action) {
      state.openVenueFarAwayModal = true; 
      state.venueIdToBlock = action.payload;
    },
    resetVenueIdtoBlock(state){
      state.venueIdToBlock = 0;
    },
    closeVenueFarAwayModal(state) {
      state.openVenueFarAwayModal = false; 
    },
  },
});

export const { openVenueLocationModal, closeVenueLocationModal, openVenueFarAwayModal, closeVenueFarAwayModal, resetVenueIdtoBlock } = venueLocationModalSlice.actions;

export default venueLocationModalSlice.reducer;
