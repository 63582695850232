import React, { useState } from "react";
import { useTheme, Button, Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { PATH_AUTH } from "src/routes/paths";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import UserProfileMenu from "../userProfile/UserProfileMenu";

const HeaderAuthButton = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const { user, isLoggedIn } = useSelector((state) => state.login);
  const [isOpen, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isMediumScreen ? (
        <>
          {isLoggedIn ? (
            <>
              <Box
                onClick={handleOpen}
                component="div"
                sx={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#FCA92E",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                {user?.firstName?.charAt(0)}

                <UserProfileMenu
                  isOpen={isOpen}
                  anchorEl={isOpen}
                  handleClose={handleClose}
                />
              </Box>
            </>
          ) : (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",

                  "@media (max-width: 768px)": {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  },
                }}
              >
                {location?.pathname !== "/auth/login" &&
                  location?.pathname !== "/auth/reset-password" &&
                  location?.pathname !== "/auth/verify" && (
                    <Box>
                      <Button
                        sx={{
                          width: "100%",
                          padding: "0px 20px",
                          height: "42px",

                          "@media (max-width: 330px)": {
                            height: "32px",
                            p: 0,
                            fontSize: "12px",
                          },
                        }}
                        variant="contained"
                        href={PATH_AUTH.login}
                      >
                        Log in
                      </Button>
                    </Box>
                  )}
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          {isLoggedIn ? (
            <>
              <Button
                sx={{ color: "#FCA92E", minWidth: "40px", padding: "0px" }}
                target="_blank"
                rel="noopener"
                onClick={handleOpen}
              >
                <img
                  src="/assets/header-tabs/person.svg"
                  alt="Cart icon"
                  style={{ height: "22px", width: "22px" }}
                />
              </Button>
              <UserProfileMenu
                isOpen={isOpen}
                anchorEl={isOpen}
                handleClose={handleClose}
              />
            </>
          ) : (
            <>
              {location?.pathname !== "/auth/login" &&
                location?.pathname !== "/auth/reset-password" && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "40px",

                      "@media (max-width: 768px)": {
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Box>
                      <Button
                        sx={{
                          width: "100%",
                          padding: "0px 20px",
                          height: "42px",
                          "@media (max-width: 430px)": {
                            height: "32px",
                            p: 0,
                            fontSize: "12px",
                          },
                        }}
                        variant="contained"
                        href={PATH_AUTH.login}
                      >
                        Log in
                      </Button>
                    </Box>
                  </Box>
                )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default HeaderAuthButton;
