// scroll bar
import "simplebar/src/simplebar.css";

import io from "socket.io-client";
// lightbox
/* eslint-disable import/no-unresolved */
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

// map
import "./utils/mapboxgl";
import "mapbox-gl/dist/mapbox-gl.css";

// editor
import "react-quill/dist/quill.snow.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------
import { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
// redux
import { store, persistor } from "./redux/store";
// routes
import Router from "./routes";
// theme
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ThemeProvider from "./theme";
import { StyledChart } from "./components/chart";
import SnackbarProvider from "./components/snackbar";
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";
import { ThemeSettings, SettingsProvider } from "./components/settings";
import { AuthProvider } from "./auth/JwtContext";
import { CartProvider } from "./pages/Venue/CartContext";

export const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

export default function App() {

  useEffect(() => {
    const isProduction = process.env.REACT_APP_ENV === 'production';
    const script = document.createElement('script');
    script.src = isProduction
      ? 'https://js.xstak.com/xpay.js'
      : 'https://js.xstak.com/xpay-stage.js';
    script.onload = () => {
      console.log('Payment script loaded successfully');
    };
    script.onerror = () => {
      console.error('Error loading payment script');
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <ReduxProvider store={store}>
      <CartProvider>
        <AuthProvider>
          <HelmetProvider>
            <PersistGate loading={null} persistor={persistor}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <BrowserRouter>
                    <ScrollToTop />
                    <MotionLazyContainer>
                      <ThemeProvider>
                        <ThemeSettings>
                          <SnackbarProvider>
                            <StyledChart />
                            <Router />
                          </SnackbarProvider>
                        </ThemeSettings>
                      </ThemeProvider>
                    </MotionLazyContainer>
                  </BrowserRouter>
                </SettingsProvider>
              </LocalizationProvider>
            </PersistGate>
          </HelmetProvider>
        </AuthProvider>
      </CartProvider>
    </ReduxProvider>
  );
}
