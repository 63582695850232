import { Box, Grid, Stack, Typography, Container, Chip } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { fetchImage } from "src/utils/fetchImage";
import { useSelector, useDispatch } from "react-redux";
import { getOrderHistoryByCustomerId } from "src/redux/slices/orderPlaceSlice";
import { convertTimeFormat } from "src/utils/formatTime";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import EmptyContent from "src/components/empty-content/EmptyContent";
import Image from "src/components/image/Image";
import Ordermodal from "./Ordermodal";
import { fNumber } from "src/utils/formatNumber";

const OrderHistory = () => {
  const { orderHistory, isLoading } = useSelector((state) => state.orderHistory);
  const { user } = useSelector((state) => state.login);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const dispatch = useDispatch();
  const filteredOrders =
    orderHistory &&
    orderHistory.length > 0 &&
    orderHistory?.filter((item) => ["completed","cancelled","abort"].includes(item.state));
  const hasFilteredOrders = filteredOrders && filteredOrders.length > 0;
  const getOrder = useCallback(async () => {
    try {
      await dispatch(getOrderHistoryByCustomerId(user?.id));
    } catch (error) {}
  }, [orderHistory]);

  useEffect(() => {
    getOrder();
  }, []);
  const [imageURLs, setImageURLs] = useState([]);

  const [detail, setDetail] = useState("");

  const ItemDetails = (elem) => {
    setDetail(elem);
    setOpenConfirm(true);
  };

  useEffect(() => {
    const fetchImagesForOrders = async () => {
      if (filteredOrders && filteredOrders.length > 0) {
        const urls = await Promise.all(
          filteredOrders.map(async (elem) => {
            try {
              const res = await fetchImage(elem.venueId.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForOrders();
  }, [hasFilteredOrders]);

  const getChipStyle = (status) => {
    switch (status) {
      case 'completed':
        return {
          backgroundColor: '#e0f2f1',  
          color: '#2e7d32',
        };
      case 'cancelled':
        return {
          backgroundColor: '#ffebee',  
          color: '#d32f2f',         
        };
        case 'abort':
          return {
            backgroundColor: '#fff3e0',
            color: '#f57c00',        
          };
      default:
        return {
          backgroundColor: '#f5f5f5',  
          color: '#9e9e9e',      
        };
    }
  };

  const capitilizeText = (state)=>{
     return state == 'completed' ? 'Completed':state == 'abort'?'Abort':state=='cancelled'?'Cancelled':''
  }

  return (
    <>
      <Box
        sx={{
          marginLeft: "35px",
          marginRight: "35px",
          "@media (max-width: 1025px)": {
            marginLeft: "0px",
            marginRight: "0px",
          },
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              marginTop: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              overflow: "hidden",
              "@media (max-width: 899px)": {
                marginTop: "150px",
              },
            }}
          >
            <Stack>
              <Typography variant="h3">Order History</Typography>
            </Stack>
          </Box>
          {isLoading ? (
            < LoadingScreen width="100px" height="100px" marginT="0" h="70vh" />
          ) : (
            <>
              {hasFilteredOrders ? (
                <Box>
                  {filteredOrders?.length > 0 ? (
                    <Grid container spacing={3} mt={1} mb={5}>
                      {filteredOrders &&
                        filteredOrders?.map((elem, index) => {
                          return (
                            <Grid item xs={12} md={6} key={index}>
                              <Box
                                onClick={() => ItemDetails(elem)}
                                sx={{
                                  flexDirection: "row",
                                  padding: "10px",
                                  border: "1px solid rgba(0, 0, 0, 0.15)",
                                  borderRadius: "5px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                  cursor: "pointer",
                                }}
                              >
                                <Stack
                                  sx={{
                                    flexDirection: "row",
                                  }}
                                >
                                  <Box>
                                    <Image
                                      src={imageURLs[index]?imageURLs[index]:'/assets/placeholder.png'}
                                      alt={"image"}
                                      sx={{
                                        height: { xs: '100px', sm: '90px', md: '110px', lg: '120px' },
                                        width: { xs: '110px', sm: '130px', md: '160px', lg: '185px' },
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      marginTop: "-4px",
                                      marginLeft: "15px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <Typography sx={{ fontWeight: 600 }}>
                                      {elem?.venueId?.name}
                                    </Typography>
                                    <Typography sx={{ fontWeight: 600, fontSize:'0.9rem' }}>
                                      Order #
                                      {' '+elem?.billNumber}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#F08203",
                                        marginTop: "4px",
                                        fontSize: "16px",
                                        "@media (max-width: 1440px)": {
                                          fontSize: "14px",
                                        },
                                      }}
                                    >
                                      Rs. {fNumber(elem?.total)}
                                    </Typography>
                                    <Box sx={{ display: 'flex' , marginTop:0.4}}>
                                      <Chip
                                        label={capitilizeText(elem.state)}
                                        sx={{
                                          ...getChipStyle(elem.state),     
                                          borderRadius: '16px',      
                                          fontWeight: 'bold',
                                          height: '22px',
                                          fontSize: '11px',
                                        }}
                                    />
                                  </Box>
                                    <Typography
                                      variant="span"
                                      sx={{
                                        // color: "#F08203",
                                        marginTop: "4px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {convertTimeFormat(elem?.createdAt)}
                                    </Typography>
                                    {/* {elem?.items?.map((item, index) => {
                                      return (
                                        <Stack
                                          key={index}
                                          sx={{ flexDirection: "column" }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              "@media (max-width: 1440px)": {
                                                fontSize: "12px",
                                              },
                                            }}
                                          >
                                            {item?.qty} X {item?.name}
                                          </Typography>
                                        </Stack>
                                      );
                                    })} */}
                                  </Box>
                                  
                                </Stack>
                              </Box>
                            </Grid>
                          );
                        })}
                    </Grid>
                  ) : (
                    <EmptyContent
                      title="No Order"
                      sx={{
                        "& span.MuiBox-root": { height: 160 },
                      }}
                    />
                  )}
                </Box>
              ) : (
                <EmptyContent
                  title="No Order"
                  sx={{
                    "& span.MuiBox-root": { height: 160 },
                  }}
                />
              )}
            </>
          )}
        </Container>
      </Box>
      {openConfirm && (
        <Ordermodal
          width="sm"
          open={openConfirm}
          onClose={handleCloseConfirm}
          detail={detail}
        />
      )}
    </>
  );
};
export default OrderHistory;
