import { Helmet } from "react-helmet-async";
import VenueHeader from "../VenueHeader";
import FooterLogin from "src/layouts/compact/FooterLogin";
import OrderHistory from "./OrderHistory";
import { Box } from "@mui/material";

const OrdeHistoryPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Egora-Order</title>
      </Helmet>
      <VenueHeader />
      <OrderHistory />
      <FooterLogin />
    </Box>
  );
};
export default OrdeHistoryPage;
