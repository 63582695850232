import {
  Stack,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";

export default function VenueNotAvailableModal({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          padding: "16px 24px",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Typography
          sx={{
            width: '100%',
            textAlign: "center",
            fontWeight: "600",
            fontSize: "20px",
            color: "#333",
          }}
        >
          Venue Not Available
        </Typography>

        <IconButton aria-label="close" onClick={onClose}>
          <Iconify
            sx={{
              height: 24,
              width: 24,
            }}
            icon="mdi:close"
            color="#fca92e"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ typography: "body2", padding: "24px", textAlign: "center" }}>
        <Box mb={2}>
          <Divider />
        </Box>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            color: "#555",
            lineHeight: 1.5,
          }}
        >
          The venue is currently not available to take orders. Please check back later or choose another venue.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
