import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Step,
  Paper,
  Stepper,
  StepLabel,
  Typography,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
// utils
import { bgGradient } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const STEPS = ["Confirming", "Processing", "Ready"];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 24px)",
    right: "calc(50% + 24px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderRadius: 1,
    borderTopWidth: 3,
    borderColor: theme.palette.divider,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  height: 22,
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.disabled,
  ...(ownerState.active && {
    color: theme.palette.success.main,
  }),
  "& .QontoStepIcon-completedIcon": {
    zIndex: 1,
    fontSize: 18,
    color: theme.palette.success.main,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Iconify
          icon="eva:checkmark-fill"
          className="QontoStepIcon-completedIcon"
          width={24}
          height={24}
        />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  className: PropTypes.string,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 30,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      ...bgGradient({
        startColor: "#F08203",
        endColor: "#F08203",
      }),
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      ...bgGradient({
        startColor: "#F08203",
        endColor: "#F08203",
      }),
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    width: "92%",
    border: 0,
    borderRadius: 1,
    backgroundColor: "#CCC8C8B5",
    margin: "0 auto",
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 60,
  height: 60,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: "#637381",
  backgroundColor: theme.palette.mode === "light" ? " #CCC8C8B5" : " #CCC8C8B5",
  ...(ownerState.active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: theme.palette.common.white,
    ...bgGradient({
      startColor: "#F08203",
      endColor: "#F08203",
    }),
  }),
  ...(ownerState.completed && {
    color: theme.palette.common.white,
    ...bgGradient({
      startColor: "#F08203",
      endColor: "#F08203",
    }),
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: (
      <img
        src="/assets/order/confirming.svg"
        alt="Confirming icon"
        style={{ height: "25px", width: "25px" }}
      />
    ),
    2:
      active !== completed ? (
        <img
          src="/assets/order/processing.svg"
          alt="Processing icon"
          style={{ height: "25px", width: "25px" }}
        />
      ) : (
        <img
          src="/assets/order/processing-gray.svg"
          alt="Processing icon"
          style={{ height: "25px", width: "25px" }}
        />
      ),
    3: active ? (
      <img
        src="/assets/order/ready-white.svg"
        alt="Ready icon"
        style={{ height: "25px", width: "25px" }}
      />
    ) : (
      <img
        src="/assets/order/ready.svg"
        alt="Ready icon"
        style={{ height: "25px", width: "25px" }}
      />
    ),
  };
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.number,
  completed: PropTypes.bool,
  className: PropTypes.string,
};

export default function OrderCustomizedSteppers({ orderStatus }) {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (orderStatus === "pending") {
      setActiveStep(0);
    } else if (orderStatus === "processing") {
      setActiveStep(1);
    } else if (orderStatus === "finished" || orderStatus==="completed") {
      setActiveStep(2);
    }
  }, [orderStatus]);

  return (
    <>
      <Box sx={{ mb: 5 }} />

      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {STEPS.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Typography
                sx={{
                  fontWeight: 600,
                  "@media (max-width: 600px)": {
                    fontSize: "14px",
                  },
                }}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === STEPS.length ? (
        <Paper
          sx={{
            p: 3,
            my: 3,
            minHeight: 120,
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
          }}
        >
          <Typography sx={{ my: 1 }}>
            All steps completed - the order is finished
          </Typography>
        </Paper>
      ) : null}
    </>
  );
}
