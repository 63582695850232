import React, { useEffect } from "react";
import { useCart } from "../CartContext";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTipToCart } from "src/redux/slices/cardSlice";
import { fNumber } from "src/utils/formatNumber";

const CartModalAction = ({
  venueIdToBlock,
  onClose,
  setIsDisabled,
  isDisabled,
  serviceFee,
  setIsScanning,
  taxAmount,
  setOpenCarInformationModal,
}) => {
  let orderType = localStorage.getItem("mode");
  const { tableData } = useSelector((state) => state.tableData);
  const { cardItems } = useSelector((state) => state?.cardSlice);
  const { isLoggedIn } = useSelector((state) => state.login);
  const { calculateFinalTotal, selectedTip } = useCart();
  const navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    setIsDisabled( ( (orderType === "dineIn" || orderType === "QrDineIn") && tableData.length == 0) || (cardItems?.data.venueId == venueIdToBlock) );
  }, [cardItems]);

  const handleCheckoutClick = () => {
    dispatch(addTipToCart(selectedTip));
    onClose();
    if (orderType == "QrPickUp") {
      setOpenCarInformationModal(true);
    } else if (isLoggedIn) {
      navigate(`/checkout`);
    } else {
      navigate(`/auth/login?type=${orderType}`);
    }
  };

  return (
    <>
      <Box>
        {cardItems?.data?.items?.length > 0 && (
          <Box>
            {isDisabled &&  (orderType === "dineIn" || orderType === "QrDineIn") &&  (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems:'center',
                    paddingBottom: "14px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    Please Scan the Table First Before Placing the Order
                  </Typography>

                  <img
                    src="/assets/order/qrCode-1.svg"
                    alt="Processing icon"
                    style={{ height: "45px", width: "45px" }}
                    onClick={() => setIsScanning(true)}
                  />
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Typography sx={{ fontWeight: "800", fontSize: "16px" }}>
                Total
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: "15px",}}
              >
                Rs.{" "}
                {fNumber((
                  Number(calculateFinalTotal(cardItems?.data?.items)) +
                  Number(taxAmount) +
                  Number(serviceFee)
                ).toFixed(2))}
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "10px",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleCheckoutClick}
                disabled={isDisabled}
                sx={{
                  width: "100%",
                  height: "40px",
                  fontSize: "16px",
                }}
              >
                Checkout
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                ></Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CartModalAction;
