import React, { useCallback, useEffect } from "react";
import { useCart } from "../CartContext";

import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  Card,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  removeItemFromCart,
  updateItemsToCard,
  setPickUpAndDineIn,
} from "src/redux/slices/cardSlice";
import { fetchImage } from "src/utils/fetchImage";
import Image from "src/components/image/Image";
import useSession from "src/utils/useSession";
import { CustomTextFieldForCart } from "src/components/custom-input/CustomTextField";
import VenueItmeModalEdit from "../Venue Profiles/venueModalEdit/VenueItmeModalEdit";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";
import { color } from "@mui/system";
import { useLocation } from "react-router-dom";
import { fNumber } from "src/utils/formatNumber";

const CartModalContent = ({ taxAmount, serviceFee, open, onClose }) => {
  const sessionInfo = useSession();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { cardItems } = useSelector((state) => state.cardSlice);
  const {
    selectedTip,
    handleTipChange,
    calculateSubTotal,
    calculateMenuItemTotal,
  } = useCart();
  const [openConfirm1, setOpenConfirm1] = useState(false);
  const [orderItem, setOrderItem] = useState(null);
  const handleMenuItemClick = (item) => {
    setOpenConfirm1(true);
    setOrderItem(item);
  };

  const closeModal = () => {
    setOpenConfirm1(false);
  };

  const handleRemoveFromCart = async (item) => {
    await dispatch(
      removeItemFromCart(
        cardItems?.data?.id,
        item?.cartItemId,
        sessionInfo?.sessionId,
        item?.venueId,
        item?.levelId
      )
    );
    if (cardItems?.data?.items.length === 1) {
      dispatch(
        setPickUpAndDineIn({
          isPickUp: false,
          isDineIn: false,
        })
      );
      handleTipChange(null);
      navigate(`/venue/${cardItems?.data?.venueId}`);
      return;
    }
  };

  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    const fetchImagesForCart = async () => {
      if (cardItems?.data?.items && cardItems?.data?.items.length > 0) {
        const urls = await Promise.all(
          cardItems?.data?.items.map(async (elem) => {
            try {
              const res = await fetchImage(elem.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForCart();
  }, [cardItems]);

  const updateItemFromCardAddByOne = useCallback(async (item) => {
    try {
      const updatedItem = {
        ...item,
        qty: item.qty + 1,
      };
      await dispatch(updateItemsToCard(updatedItem, sessionInfo?.sessionId));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const updateItemFromCardDecByOne = useCallback(async (item) => {
    try {
      const updatedItem = {
        ...item,
        qty: item.qty - 1,
      };
      await dispatch(updateItemsToCard(updatedItem, sessionInfo?.sessionId));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const tipOptions = [
    { label: "Rs. 0", value: 0 },
    { label: "Rs. 50", value: 50 },
    { label: "Rs. 100", value: 100 },
  ];

  const venuePath = `/venue/${cardItems?.data?.venueId}`;

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleMenuPage = () => {
    navigate(`/venue/${cardItems?.data?.venueId}`);
  };

  return (
    <Box>
      {cardItems?.data?.items?.map((cartItem, index) => {
        return (
          <>
            <Box
              key={index}
              sx={{
                display: "flex",
                padding: "10px 0",
                justifyContent: "space-between",
                alignItems: "start",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      display: "inline-block",
                      padding: "5px",
                      borderRadius: "8px",
                      "@media (max-width: 600px)": {
                        padding: "3px",
                      },
                    }}
                  >
                    <Image
                      src={
                        imageURLs[index]
                          ? imageURLs[index]
                          : "/assets/placeholder.png"
                      }
                      sx={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "5px",
                        "@media (max-width: 600px)": {
                          height: "50px",
                          width: "50px",
                        },
                      }}
                    />
                  </Box>

                  <Stack>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "self-end",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        onClick={() => handleMenuItemClick(cartItem)}
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          marginLeft: "9px",
                          cursor: "pointer",
                          "@media (max-width: 600px)": {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px",
                          },

                          "@media (max-width: 330px)": {
                            maxWidth: "100px",
                          },
                        }}
                      >
                        {cartItem?.name}
                      </Typography>
                      <img
                        src="/assets/order/edit.svg"
                        alt="Dine In"
                        style={{
                          marginLeft: "7px",
                          color: "#F08203",
                          cursor: "pointer",
                          width: "12px",
                          marginBottom: "3px",
                        }}
                        onClick={() => handleMenuItemClick(cartItem)}
                      />
                    </Box>

                    {/* {cartItem?.selectedVariant ||
                  (cartItem?.groups && cartItem?.groups.length > 0) ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={{
                          ml: 1.3,
                          fontSize: 12,
                          fontWeight: "500",
                          color: "#F08203",
                        }}
                      >
                        Items:
                      </Typography>
                      <Typography
                        sx={{
                          ml: 1,
                          fontSize: 12,
                          "@media (max-width: 600px)": {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "80px",
                          },
                        }}
                      >
                        {cartItem?.selectedVariant?.name}
                        {cartItem?.selectedVariant &&
                        cartItem?.groups &&
                        cartItem?.groups.length > 0
                          ? ", "
                          : ""}
                        {cartItem?.groups?.map((sauce, sauceIndex) => {
                          const items = sauce?.items || [];
                          const totalItems = items.length;

                          const limitedItems = items.slice(0, 2);

                          return (
                            <span key={sauceIndex}>
                              {limitedItems.map((sauceItem, sauceItemIndex) => (
                                <span key={sauceItemIndex}>
                                  {sauceItem?.item}
                                  {sauceItemIndex !== limitedItems.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              ))}
                              {totalItems > 2 && "..."}

                              {sauceIndex !== cartItem?.groups.length - 1
                                ? ", "
                                : ""}
                            </span>
                          );
                        })}
                      </Typography>
                    </Box>
                  ) : null} */}

                    {cartItem?.selectedVariant && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            ml: 1.3,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "#666",
                          }}
                        >
                          Variant:
                        </Typography>
                        <Typography sx={{ ml: 1, fontSize: 12 }}>
                          {cartItem?.selectedVariant?.name}
                        </Typography>
                      </Box>
                    )}

                    {cartItem?.groups && cartItem?.groups?.length > 0 && (
                      <>
                        {cartItem?.groups?.map((sauce) => (
                          <Typography
                            key={index}
                            variant="caption"
                            sx={{
                              color: "#666",
                              display: "flex",
                              flexWrap: "wrap",
                              flexDirection: "row",
                              marginLeft: "10px",
                              gap: "2px",
                              fontWeight: "bold",
                              "@media (max-width: 600px)": {
                                fontSize: "10px",
                                marginLeft: "5px",
                                gap: "1px",
                              },
                            }}
                          >
                            + {sauce?.name} :
                            <Typography
                              variant="caption"
                              sx={{
                                color: "#666",
                                display: "flex",
                                marginLeft: "5px",
                                gap: "2px",
                                flexWrap: "wrap",
                                "@media (max-width: 600px)": {
                                  fontSize: "10px",
                                  marginLeft: "2px",
                                  gap: "1px",
                                },
                              }}
                            >
                              {sauce?.items?.map((sauceItem, sauceIndex) => (
                                <span key={sauceIndex}>
                                  {sauceItem?.item}
                                  {` (Rs. ${fNumber(sauceItem.price * cartItem.qty)})`}
                                  {sauceIndex !== sauce?.items?.length - 1 &&
                                    ", "}
                                </span>
                              ))}
                            </Typography>
                          </Typography>
                        ))}
                      </>
                    )}
                    <Typography
                      variant="caption"
                      sx={{
                        display: "flex",
                        marginLeft: "10px",
                        gap: "2px",

                        "@media (max-width: 600px)": {
                          fontSize: "10px",
                          "@media (max-width: 600px)": {
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "80px",
                          },
                        },
                      }}
                    >
                      <span>{cartItem?.notes}</span>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#F08203",
                        marginTop: "4px",
                        fontSize: "16px",
                        marginLeft: "10px",
                        fontWeight: "600",
                        "@media (max-width: 1440px)": {
                          fontSize: "12px",
                        },
                      }}
                    >
                      {calculateMenuItemTotal(cartItem)}
                    </Typography>
                  </Stack>
                </Box>
              </Box>

              <Box>
                <Button
                  sx={{
                    height: "30px",
                    width: "130px",
                    borderRadius: "20px",
                    border: "2px solid #F08203",
                    marginRight: "10px",
                    "@media (max-width: 600px)": {
                      width: "100px",
                      height: "25px",
                      marginRight: "5px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {cartItem.qty > 1 ? (
                      <Button
                        onClick={() => updateItemFromCardDecByOne(cartItem)}
                      >
                        <Iconify
                          sx={{
                            color: "#F08203",
                          }}
                          icon={"ic:baseline-minus"}
                        />
                      </Button>
                    ) : (
                      <Button onClick={() => handleRemoveFromCart(cartItem)}>
                        <Iconify
                          sx={{
                            color: "#F08203",
                          }}
                          icon={"mdi:trash-outline"}
                        />
                      </Button>
                    )}
                    <Typography sx={{ fontSize: "16px", color: "#F08203" }}>
                      {cartItem.qty}
                    </Typography>
                    <Button
                      onClick={() => updateItemFromCardAddByOne(cartItem)}
                    >
                      <Iconify
                        sx={{
                          color: "#F08203",
                        }}
                        icon={"ic:baseline-plus"}
                      />
                    </Button>
                  </Box>
                </Button>
              </Box>
            </Box>

            {index < cardItems.data.items.length - 1 && (
              <Divider
                sx={{
                  marginRight: "13px",
                  marginLeft: "8px",
                  marginTop: "10px",
                  borderColor: "#F08203",
                }}
              />
            )}
          </>
        );
      })}
      {cardItems?.data?.items?.length > 0 && (
        <>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              margin: "14px",
              fontWeight: "600",
              fontSize: "15px",
              cursor: "pointer",
              color: "#F08203",
            }}
            onClick={handleMenuPage}
            style={{ display: currentPath !== venuePath ? "flex" : "none" }}
          >
            + Add more Items
          </Typography>

          <Card
            sx={{
              marginBottom: "20px",
              border: "0.5px solid #C0B9B9",
            }}
          >
            <Box>
              <Typography
                sx={{
                  margin: "14px",
                  fontWeight: "700",
                  fontSize: "15px",
                }}
              >
                Order Summary
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "14px",
                  marginTop: "20px",
                }}
              >
                <Typography sx={{ fontWeight: "500", fontSize: "15px" }}>
                  Sub Total
                </Typography>
                <Typography sx={{ fontWeight: "600", fontSize: "15px" }}>
                  Rs. {fNumber(calculateSubTotal(cardItems?.data?.items))}
                </Typography>
              </Stack>

              {cardItems?.data?.venueDetail?.configurations?.isServiceFees &&
                (cardItems?.data?.venueDetail?.serviceFeesObject
                  ?.paymentOption === "Cash" ||
                  cardItems?.data?.venueDetail?.serviceFeesObject
                    ?.paymentOption === "Both") && (
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      margin: "14px",
                      marginTop: "-12px",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Service Fee
                      <IconButton>
                        <Iconify
                          sx={{
                            height: "18px",
                            width: "18px",
                            color: "#F08203",
                          }}
                          icon={"ph:info"}
                        />{" "}
                      </IconButton>
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Rs. {fNumber(serviceFee)}
                    </Typography>
                  </Stack>
                )}

              {cardItems?.data?.venueDetail?.configurations?.isPayingTax && (
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "14px",
                    marginTop: "-12px",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    Tax
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    Rs. {taxAmount ? fNumber(taxAmount) : 0}
                  </Typography>
                </Stack>
              )}
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  margin: "14px",
                  alignItems: "center",
                  "@media (max-width: 600px)": {
                    alignItems: "start",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  Tip
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    gap: "20px",
                    "@media (max-width: 600px)": {
                      gap: "10px",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    },
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {tipOptions.map((tipOption) => (
                      <Button
                        key={tipOption.value}
                        variant={
                          selectedTip === tipOption.value
                            ? "contained"
                            : "outlined"
                        }
                        sx={{
                          p: 0,
                          height: "36px",
                          fontSize: { xs: "12px", sm: "14px" },
                        }}
                        onClick={() => handleTipChange(tipOption.value)}
                      >
                        {tipOption.label}
                      </Button>
                    ))}
                  </Stack>

                  <CustomTextFieldForCart
                    type="number"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="custom tip"
                    value={selectedTip}
                    onChange={(e) => handleTipChange(e.target.value)}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rs.</InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </Card>
        </>
      )}
      {openConfirm1 && (
        <VenueItmeModalEdit
          width="md"
          open={openConfirm1}
          setOpenConfirm1={setOpenConfirm1}
          onClose={closeModal}
          item={orderItem}
          data={cardItems?.data}
        />
      )}
    </Box>
  );
};

export default CartModalContent;
