// import { Box, Link, Stack, Typography } from "@mui/material";
// import { Link as RouterLink } from "react-router-dom";
// import React from "react";
// import Iconify from "src/components/iconify/Iconify";

// const ResendCode = ({ handleChangeVerificationPath, verificationPath }) => {
//   return (
//     <Box>
//       <Stack spacing={1} sx={{}}>
//         <Link
//           component={RouterLink}
//           color="inherit"
//           variant="subtitle2"
//           sx={{
//             alignItems: "center",
//             display: "inline-flex",
//           }}>
//           <Iconify
//             sx={{ color: "#FCA92E" }}
//             icon="material-symbols:rotate-left-rounded"
//             width={16}
//           />
//           <Typography
//             variant="body2"
//             sx={{
//               ml: 1,
//               color: "#FCA92E",
//             }}>
//             Resend Code
//           </Typography>
//         </Link>
//         <Typography variant="body2">
//           Can't receive the Code to{" "}
//           {verificationPath === "Email" ? "Email" : "Mobile Number"}, send code
//           to{" "}
//           <Link
//             style={{ cursor: "pointer" }}
//             onClick={handleChangeVerificationPath}
//             underline="always">
//             {verificationPath === "Phone" ? "Email" : "Phone Number"}
//           </Link>
//         </Typography>
//       </Stack>
//     </Box>
//   );
// };

// export default ResendCode;

import { Box, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Iconify from "src/components/iconify/Iconify";
import { getVerifyCode } from "src/utils/Apis";
import { useSelector } from "react-redux";

const ResendCode = ({  phone,phoneNumberForModal }) => {
  const [timer, setTimer] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { user } = useSelector((state) => state.login);
  useEffect(() => {
    let countdown;
    if (isButtonDisabled) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            clearInterval(countdown);
            setIsButtonDisabled(false);
            return 0;
          }
        });
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [isButtonDisabled]);

  const handleResendCode = async () => {
    const getResgisteredUserId = localStorage.getItem("reg_Id");
    const queryParams = new URLSearchParams(window.location.search);
    const verificationPath = queryParams.get("verify"); 
    const phoneNumber = queryParams.get("phoneNumber");
    let apiData = {};
    if (verificationPath === "Phone" || phone) {
      apiData = { isPhone: "true", phone: phoneNumber || phoneNumberForModal };
    } else {
      apiData = { isEmail: "true" };
    }
  
    setIsButtonDisabled(true);
    setTimer(60);
    await getVerifyCode(apiData, getResgisteredUserId || user?.id);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  return (
    <Box>
      <Stack spacing={1} sx={{}}>
        <Link
          component={RouterLink}
          color="inherit"
          variant="subtitle2"
          onClick={handleResendCode}
          sx={{
            alignItems: "center",
            display: "inline-flex",
            cursor: isButtonDisabled ? "not-allowed" : "pointer",
            pointerEvents: isButtonDisabled ? "none" : "auto",
          }}
        >
          <Iconify
            sx={{ color: isButtonDisabled ? "gray" : "#FCA92E" }}
            icon="material-symbols:rotate-left-rounded"
            width={16}
          />
          <Typography
            variant="body2"
            sx={{
              ml: 1,
              color: isButtonDisabled ? "gray" : "#FCA92E",
            }}
          >
            {isButtonDisabled ? `Resend Code (${timer}s)` : "Resend Code"}
          </Typography>
        </Link>
        {/* <Typography variant="body2">
          Can't receive the Code to{" "}
          {verificationPath === "Email" ? "Email" : "Mobile Number"}, send code
          to{" "}
          <Link
            style={{ cursor: "pointer" }}
            onClick={handleChangeVerificationPath}
            underline="always">
            {verificationPath === "Phone" ? "Email" : "Phone Number"}
          </Link>
        </Typography> */}
      </Stack>
    </Box>
  );
};

export default ResendCode;
