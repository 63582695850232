import { useEffect } from "react";
import { Box, Typography, Divider, IconButton, Grid } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import Image from "src/components/image/Image";
import { DialogTitle, DialogContent } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useState } from "react";
import { fetchImage } from "src/utils/fetchImage";
import Processing from "./Processing";
import Preparing from "./Preparing";
import { useSelector } from "react-redux";
import Ready from "./Ready";
import { useCart } from "../../CartContext";
import Completed from "./Completed";
import { fNumber } from "src/utils/formatNumber";

const ProcessingModal = ({ detail, width, open, onClose, onCancelSuccess }) => {
  const [toggleContent, setToggleContent] = useState(false);
  const [imageURLs, setImageURLs] = useState([]);
  const { calculateMenuItemTotal, selectedTip } = useCart();
  const { cardItems } = useSelector((state) => state.cardSlice);

  useEffect(() => {
    const fetchImagesForOrders = async () => {
      if (detail?.items?.length > 0) {
        const urls = await Promise.all(
          detail?.items?.map(async (elem) => {
            try {
              const res = await fetchImage(elem.photoURL);
              return res;
            } catch (error) {
              console.error("Error fetching data:", error);
              return null;
            }
          })
        );
        setImageURLs(urls);
      }
    };

    fetchImagesForOrders();
  }, [detail]);

  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box variant="h2">
                {detail?.state === "completed"
                  ? "Thank you for Ordering"
                  : "Order Processing"}
              </Box>
            </Box>
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
          <Box mt={3}>
            <Divider />
          </Box>
        </DialogTitle>

        <DialogContent sx={{ typography: "body2" }}>
          <>
            {detail?.state === "pending" && (
              <Processing
                detail={detail}
                toggleContent={toggleContent}
                setToggleContent={setToggleContent}
                onClose={onClose}
                onCancelSuccess={onCancelSuccess}
              />
            )}

            {detail?.state === "processing" && (
              <Preparing
                detail={detail}
                toggleContent={toggleContent}
                setToggleContent={setToggleContent}
              />
            )}

            {detail?.state === "finished" && (
              <Ready
                detail={detail}
                toggleContent={toggleContent}
                setToggleContent={setToggleContent}
              />
            )}

            {detail?.state === "completed" && (
              <Completed
                detail={detail}
                toggleContent={toggleContent}
                setToggleContent={setToggleContent}
              />
            )}

            {toggleContent && (
              <>
                {detail?.items?.length > 0 && (
                  <Box>
                    <Box mt={3}>
                      <Divider />
                    </Box>
                    {detail?.items?.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          gap: "10px",
                        }}
                        mb={1}
                        mt={1}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "start",
                            gap: "10px",
                          }}
                        >
                          <Image
                            src={
                              imageURLs[index]
                                ? imageURLs[index]
                                : "/assets/placeholder.png"
                            }
                            alt={"image"}
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "5px",
                            }}
                          />

                          <Box>
                            <Box sx={{ fontSize: "14px", ml: 1 }}>
                              {item?.qty} X {item?.name}{" "}
                            </Box>

                            {item?.groups && item?.groups?.length > 0 && (
                              <>
                                {item?.selectedVariant && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ml: 1,
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        color: "#666",
                                      }}
                                    >
                                      Variant:
                                    </Typography>
                                    <Typography sx={{ ml: 1, fontSize: 12 }}>
                                      {item?.selectedVariant?.name}
                                    </Typography>
                                  </Box>
                                )}
                                <>
                                  {item?.groups?.map((sauce) => (
                                    <Typography
                                      key={index}
                                      variant="caption"
                                      sx={{
                                        color: "#666",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        flexDirection: "row",
                                        marginLeft: "6px",
                                        gap: "2px",
                                        fontWeight: "bold",
                                        "@media (max-width: 600px)": {
                                          fontSize: "10px",
                                          gap: "1px",
                                        },
                                      }}
                                    >
                                      {sauce?.name} :
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color: "#666",
                                          display: "flex",

                                          gap: "2px",
                                          flexWrap: "wrap",
                                          "@media (max-width: 600px)": {
                                            fontSize: "10px",

                                            gap: "1px",
                                          },
                                        }}
                                      >
                                        {sauce?.items?.map((item, index) => (
                                          <span key={index}>
                                            {item?.item}
                                            {index !== sauce.items.length - 1 &&
                                              ", "}
                                          </span>
                                        ))}
                                      </Typography>
                                    </Typography>
                                  ))}
                                </>
                              </>
                            )}
                            <Box ml={2} sx={{ fontSize: "14px", color: "red" }}>
                              {item?.notes}
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ color: "#F08203" }}>
                          {calculateMenuItemTotal(item)}
                        </Box>
                      </Box>
                    ))}
                    <Box>
                      <Divider />
                    </Box>

                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      mb={2}
                      mt={2}
                    >
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "500", fontSize: "15px" }}
                        >
                          Sub Total
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            mt: 0,
                            color: "black",
                          }}
                          align="right"
                        >
                          Rs: {fNumber(detail?.subTotal)}
                        </Typography>
                      </Grid>

                      {detail?.serviceFees > 0 && (
                        <Grid item xs={6}>
                          <Typography
                            sx={{ fontWeight: "500", fontSize: "15px" }}
                          >
                            Service Fee
                          </Typography>
                        </Grid>
                      )}

                      {detail?.serviceFees > 0 && (
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "black",
                            }}
                            align="right"
                          >
                            Rs: {fNumber(detail?.serviceFees)}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "500", fontSize: "15px" }}
                        >
                          Tip 
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            color: "black",
                          }}
                          align="right"
                        >
                          Rs. {fNumber(detail?.tip)}
                        </Typography>
                      </Grid>

                      {detail?.tax > 0 && (
                        <Grid item xs={6}>
                          <Typography
                            sx={{ fontWeight: "500", fontSize: "15px" }}
                          >
                            Tax
                          </Typography>
                        </Grid>
                      )}
                      {detail?.tax > 0 && (
                        <Grid item xs={6}>
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "black",
                            }}
                            align="right"
                          >
                            Rs: {fNumber(detail?.tax)}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          Total
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "black",
                          }}
                          align="right"
                        >
                          Rs: {fNumber(detail?.total)}
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* Render images and details of each item in the cart */}
                  </Box>
                )}
              </>
            )}
          </>
        </DialogContent>
      </ConfirmDialog>
    </>
  );
};
export default ProcessingModal;
