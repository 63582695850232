import { Typography, Box, Button, Divider } from "@mui/material";
import { fNumber } from "src/utils/formatNumber";

const Variants = ({
    variants,
    selectedVariant,
    selectedSauces,
    toggleSauce,
    toggleVariantSelect,
    ...other
}) => {

    return (
        <>
            {variants?.variants?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                    <Typography
                        sx={{ fontSize: 15, marginLeft: '2px' }}
                        variant="subtitle2"
                    >
                        Variants
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                        }}
                    >
                        {variants.variants.map((variant, index) => {
                            const isSelected = variant.id === selectedVariant?.id;
                            return (
                                <Button
                                    key={index}
                                    size="small"
                                    sx={{
                                        minWidth: {
                                            xs: 'calc(50% - 8px)',
                                            sm: 'calc(50% - 8px)',
                                            md: 120
                                        },
                                        minHeight: 40,
                                        flexGrow: { xs: 0, sm: 0, md: 0 },
                                        ml: { xs: 0, sm: 0, md: 0 },
                                        mb: 1,
                                    }}
                                    variant={isSelected ? "contained" : "outlined"}
                                    disabled={variant.quantity < 1}
                                    onClick={() => toggleVariantSelect(variant)}
                                >
                                    {variant.name} (Rs. {fNumber(parseFloat(variant.price))})
                                </Button>
                            );
                        })}
                    </Box>
                </Box>
            )}
            <>
                {variants?.variants?.length > 0 &&
                    <Divider sx={{
                        mt: 1
                    }} />
                }
            </>

            {selectedVariant && (
                <Box  >
                    {selectedVariant.groups?.map((group, groupIndex) => (
                        <Box key={groupIndex} sx={{mt:1}}>
                            <Typography
                                sx={{ fontSize: 15 }} variant="subtitle2"
                            >
                                {group?.name}
                                <Typography
                                    sx={{ fontSize: 12, ml: 1 }} variant="span"
                                >
                                    ({group?.quantity}{" "}
                                    {group?.required ? " Required" : " Optional"})
                                </Typography>
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 1,
                                    marginTop: 1,
                                    fontWeight: "600",
                                }}
                            >
                                {group?.items?.map((sauce, sauceIndex) => (
                                    <Button
                                        key={sauceIndex}
                                        size="small"
                                        onClick={() => toggleSauce(group, sauce)}
                                        sx={{
                                            minWidth: {
                                                xs: 'calc(50% - 8px)',
                                                sm: 'calc(50% - 8px)',
                                                md: 120,
                                            },
                                            minHeight: 40,
                                            flexGrow: { xs: 0, sm: 0, md: 0 },
                                            ml: { xs: 0, sm: 0, md: 0 },
                                            mb: 1,
                                            // border: "1.5px solid #FCA92E",
                                            color: selectedSauces.items.some((item) =>
                                                item.items.includes(sauce)
                                            )
                                                ? "black"
                                                : " #fda92d",
                                        }}
                                        variant={
                                            selectedSauces.items.some((item) =>
                                                item.items.includes(sauce)
                                            )
                                                ? "contained"
                                                : "outlined"
                                        }
                                    >
                                        {sauce?.item} (Rs. {fNumber(parseFloat(sauce.price))})
                                    </Button>
                                ))}
                            </Box>

                            <Divider
                                sx={{
                                    mt: 1
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
};

export default Variants;
