import { Box, Divider, IconButton, Typography } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import MapSearch from "src/pages/googlemap/MapSearch";

const LocationModal = ({ width, open, onClose }) => {
  let address = localStorage.getItem("address");

  if(!address){
    address='Enter your address'
  }

  return (
    <>
      <ConfirmDialog width={width} open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography varient="h3">{address}</Typography>
            </Box>
            <IconButton aria-label="close" onClick={() => onClose(!open)}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box>
          <Divider />
        </Box>
        <DialogContent
          // sx={{
          //   typography: "body2",
          // }}
        >
          <MapSearch open={open} onClose={onClose} />
        </DialogContent>
       

        {/* <DialogActions sx={{ display: "block" }}>
        </DialogActions> */}
      </ConfirmDialog>
    </>
  );
};
export default LocationModal;
