import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Iconify from "src/components/iconify/Iconify";
import { truncateText } from "src/utils/truncateText";
import { fetchImage } from "src/utils/fetchImage";
import Image from "src/components/image/Image";
import { useRestaurantOpenClose } from "src/utils/useRestaurantOpenClose";

const AllVenuesCard = ({ item }) => {
  const [venuesDataImage, setVenuesDataImage] = useState([]);
  const getImages = useCallback(async () => {
    try {
      const res = await fetchImage(item?.photoURL);
      setVenuesDataImage(res);
    } catch (error) {
      console.error("Error fetching Images:", error);
    }
  }, [venuesDataImage, item]);
  useEffect(() => {
    getImages();
  }, [venuesDataImage, item]);

  const { currentDay, formatTime, venueStatus } =
  useRestaurantOpenClose();

  useEffect(() => {
    if (item && item.venueTimings && item.venueTimings[currentDay]) {
      const currentTime = item?.venueTimings[currentDay];
      if (currentTime) {
        formatTime(currentTime,item)
      }
    }
  }, [item, currentDay]);

  return (
    <Link
      to={`/venue/${item.id}`}
      style={{ textDecoration: "none", color: "#FCA92E" }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            // height: "200px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
            borderLeft: "1px solid rgba(0, 0, 0, 0.15)",
            borderRight: "1px solid rgba(0, 0, 0, 0.15)",
            borderRadius: "5px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            "@media (max-width: 600px)": {
              width: "100%",
              // height: "300px",
            },
          }}
        >
          <Stack
            sx={{
              width: "100%",
              height: "130px",
              "@media (max-width: 1440px)": {
                height: "120px",
              },
              "@media (max-width: 600px)": {
                height: "200px",
              },
            }}
          >
            <Image
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              src={venuesDataImage?venuesDataImage:'/assets/placeholder.png'}
              alt="Venue Images"
            />
          </Stack>
          <Stack>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "700",
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "5px",
              }}
            >
              <Link
                to={`/venue/${item.id}`}
                style={{ textDecoration: "none", color: "#FCA92E" }}
              >
                {item.name}
              </Link>

              <Typography sx={{ color: "gray",fontSize:'14px' }} component="span">
              {(Number(item?.distance)/1000).toFixed(1)} km
              </Typography>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                marginTop: "3px",
                color: "#212B36",
              }}
            >
              <Iconify
                sx={{
                  height: 20,
                  width: 30,
                }}
                icon="ion:location-outline"
                color="#fca92e"
              />
              {`${truncateText(item?.venueAddressOne, 40)}`}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "12px",
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "1px",
                marginBottom: "8px",
                color: "#212B36",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <Typography
                  sx={{ fontSize: "12px", color: ["Closed", "Temporarily Closed"].includes(venueStatus?.statusMessage) ? "red" : "green", }}
                  component="span"
                >
                  {venueStatus?.statusMessage}
                </Typography>
                {(venueStatus?.additionalInfo == "Open at" || venueStatus?.additionalInfo == "Closes at") &&
                  <span style={{ padding: "0px 5px" }}>-</span>
                }
                <Typography
                  sx={{ fontSize: "12px", marginRight: "5px" }}
                  component="span"
                >
                  {venueStatus?.additionalInfo}
                </Typography>
                <Typography sx={{ fontSize: "12px" }} component="span">
                  {venueStatus?.time}
                </Typography>
              </Typography>
              {/* Drinks - Japanese - Food */}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Link>
  );
};

export default AllVenuesCard;
