import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import api from "src/utils/axios";
import { clearTableData } from "./tableSlice";
import { resetVenueIdtoBlock } from "./VenueLocationModalSlice";

const initialState = {
  isLoading: false,
  error: null,
  order: [],
  orderHistory:[]
};

const orderPlaceSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    placeOrderItemsSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
      state.error = null;
    },
    orderHistoryItemsSuccess(state, action) {
      state.isLoading = false;
      state.orderHistory = action.payload;
      state.error = null;
    },
    placeOrderItemsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    orderHistoryItemsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default orderPlaceSlice.reducer;

export const { getOrderItemsSuccess, getOrderItemsFailure } =
  orderPlaceSlice.actions;

export function placeOrderFromCart(
  item,
  customerId,
  total,
  sessionId,
  orderType,
  customer,
  tableId,
  method,
  taxAmount,
  subTotal,
  selectedTip,
  totalServiceValue,
  location
) {
  return async (dispatch) => {
    dispatch(orderPlaceSlice.actions.startLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart/createOrder`,
        {
          customerId: customerId,
          levelId: item?.levelId,
          venueId: item?.venueId,
          total: total,
          type: "web",
          sessionId: sessionId,
          orderType,
          customer: customer ? customer : {},
          tableId,
          paymentType: method,
          tax:taxAmount,
          subTotal:subTotal,
          tip:selectedTip,
          serviceFees:totalServiceValue,
          location
        }
      );
      dispatch(orderPlaceSlice.actions.placeOrderItemsSuccess(response?.data));
      if(orderType == 'QrDineIn' || orderType == 'QrPickUp'){
        dispatch(resetVenueIdtoBlock())
        localStorage.setItem("mode", "dineIn");
        dispatch(clearTableData());
        localStorage.removeItem("venueId")
      }
      return { data: response?.data, status: response.status };
    } catch (error) {
      dispatch(
        orderPlaceSlice.actions.placeOrderItemsFailure(
          error.response?.data?.message
        )
      );
      throw error;
    }
  };
}

export function getOrderByCustomerId(customerId) {
  return async (dispatch) => {
    dispatch(orderPlaceSlice.actions.startLoading());
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/cart/getOrderByCustomerId?id=${customerId}`
      );
      dispatch(orderPlaceSlice.actions.placeOrderItemsSuccess(response?.data));
    } catch (error) {
      dispatch(
        orderPlaceSlice.actions.placeOrderItemsFailure(
          error.response?.data?.messags
        )
      );
    }
  };
}
export function getOrderHistoryByCustomerId(customerId) {
  return async (dispatch) => {
    dispatch(orderPlaceSlice.actions.startLoading());
    try {
      const response = await api.get(
        `${process.env.REACT_APP_BACKEND_URL}/cart/getOrderHistoryByCustomerId?customerId=${customerId}`
      );
      dispatch(orderPlaceSlice.actions.orderHistoryItemsSuccess(response?.data));
    } catch (error) {
      dispatch(
        orderPlaceSlice.actions.orderHistoryItemsFailure(
          error.response?.data?.messags
        )
      );
    }
  };
}


export function getActiveOrders (sessionId,userId) {
  return async (dispatch) => {
    dispatch(orderPlaceSlice.actions.startLoading());
    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/cart/getActiveOrders?sessionId=${sessionId}`;
      if (userId) {
        url += `&customerId=${userId}`;
      }
      const response = await api.get(url)
      dispatch(orderPlaceSlice.actions.placeOrderItemsSuccess(response?.data));
    } catch (error) {
      dispatch(
        orderPlaceSlice.actions.placeOrderItemsFailure(
          error.response?.data?.messags
        )
      );
    }
  };
}
