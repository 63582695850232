import { useEffect, useState } from "react";

export const useRestaurantOpenClose = () => {
  const [currentDay, setCurrentDay] = useState("");
  const [yesterday, setYesterday] = useState("");
  const [addToCartDisabledDay, setAddToCartDisabledDay] = useState("")
  const [currentFormattedTime, setCurrentFormattedTime] = useState("");
  const [showTime, setShowTime] = useState({ start: "", end: "" });
  const [formattedTimes, setFormattedTimes] = useState({ start: "", end: "" });
  const [venueStatus, setVenueStatus] = useState({
    isOpen: false,      
    statusMessage: "",         
    additionalInfo: "",    
    displayTime: ""      
  });

  const [yesterdayTimes, setYesterdayTimes] = useState({ start: "", end: "" });

  useEffect(() => {
    let today = new Date();
    let dayname = today.toLocaleDateString("en-US", { weekday: "long" }).toLowerCase()
    setCurrentDay(dayname);
    setAddToCartDisabledDay(dayname)
    let hours = today.getHours().toString().padStart(2, "0");
    let minutes = today.getMinutes().toString().padStart(2, "0");
    let seconds = today.getSeconds().toString().padStart(2, "0");
    let formattedTime = hours + ":" + minutes + ":" + seconds;
    setCurrentFormattedTime(formattedTime);
    let yesterdayDate = new Date();
    yesterdayDate.setDate(today.getDate() - 1);
    let yesterdayName = yesterdayDate.toLocaleDateString("en-US", { weekday: "long" }).toLowerCase();
    setYesterday(yesterdayName);
  }, []);


  const formatTime = (todaysTimestamp, item) => {

    // let checkVenueIsOpen
    // if (item && item.venueTimings && item.venueTimings[addToCartDisabledDay]) {
    //   checkVenueIsOpen = item?.venueTimings[addToCartDisabledDay];
    // }
    // if (item && item.venueTimings && item.venueTimings[yesterday]) {
    //   let yesterTime = item?.venueTimings[yesterday];
    //   const yesterdayStartTimeFormatted = formatSingleTime(yesterTime.startTime);
    //   const yesterdayEndTimeFormatted = formatSingleTime(yesterTime.endTime);
    //   setYesterdayTimes({ start: yesterdayStartTimeFormatted, end: yesterdayEndTimeFormatted })
    // }
    // if (todaysTimestamp) {
    //   const startFormatted = formatSingleTime(todaysTimestamp.startTime);
    //   const endFormatted = formatSingleTime(todaysTimestamp.endTime);
    //   setFormattedTimes({ start: startFormatted, end: endFormatted });
    //   setShowTime({ start: formatToAmPm(todaysTimestamp.startTime), end: formatToAmPm(todaysTimestamp.endTime) });

    //   if (!checkVenueIsOpen?.isOpen) {
    //     setVenueIsOpen(false)
    //     NextDay();
    //   }
    //   else {
    //     if (currentFormattedTime < startFormatted) {
    //       setVenueIsOpen(false)
    //     } else {
    //       if (startFormatted > endFormatted) {
    //         setVenueIsOpen(true)
    //       } else {
    //         if (currentFormattedTime > endFormatted) {
    //           NextDay()
    //           setVenueIsOpen(false)
    //         } else {
    //           setVenueIsOpen(true)
    //         }
    //       }
    //     }
    //     if (yesterdayTimes.start > yesterdayTimes.end) {
    //       if (currentFormattedTime > yesterdayTimes.end) {
    //         setVenueIsOpen(false)
    //       } else {
    //         setVenueIsOpen(true)
    //       }
    //     }
    //   }
    // }


    const startFormatted = formatSingleTime(todaysTimestamp.startTime);
    console.log('startFormatted', startFormatted);

    const endFormatted = formatSingleTime(todaysTimestamp.endTime);
    console.log('endFormatted', endFormatted, currentFormattedTime);

    setFormattedTimes({ start: startFormatted, end: endFormatted });
    setShowTime({ start: formatToAmPm(todaysTimestamp.startTime), end: formatToAmPm(todaysTimestamp.endTime) });


    if (startFormatted > endFormatted) {
      console.log(' means venue closed next day any time')
      // NextDay()
      // setVenueStatus({isOpen:true,statusMessage:"Open",additionalInfo:"Closes at",time:formatToAmPm(todaysTimestamp.endTime)})
      if (item?.isOnline && currentFormattedTime <= endFormatted) {
        console.log('open and current time not exceed end time')
        setVenueStatus({isOpen:true,statusMessage:"Open",additionalInfo:"Closes at",time:formatToAmPm(todaysTimestamp.endTime)})
      }
      else if (!item?.isOnline && currentFormattedTime >= startFormatted && currentFormattedTime <= endFormatted) {
        console.log('Temporarily closed',);
        setVenueStatus({isOpen:false,statusMessage:"Temporarily Closed",additionalInfo:"",time:""})
        // setVenueStatus(false)

      }
      else if (item?.isOnline && currentFormattedTime >= endFormatted) {
        setVenueStatus({isOpen:true,statusMessage:"Open",additionalInfo:"Closes at",time:formatToAmPm(todaysTimestamp.endTime)})
        console.log('Always open');
        // setVenueStatus(true)
      }
      else if (!item?.isOnline && currentFormattedTime <= startFormatted) {
        console.log('today');
        setVenueStatus({isOpen:false,statusMessage:"Closed",additionalInfo:"Open at",time:formatToAmPm(todaysTimestamp.startTime)})
        // setVenueStatus(false)
        console.log('closed before start time')
      }
      else {
        console.log('closed');
        NextDay()
        setVenueStatus({isOpen:false,statusMessage:"Closed",additionalInfo:"Open at",time:formatToAmPm(todaysTimestamp.startTime)})
        // setVenueStatus(false)
      }
    }
    else {
      if (startFormatted < endFormatted) {
        console.log('means venue closed in same day any time')
        if (item?.isOnline && currentFormattedTime <= endFormatted) {
          console.log('open and current time not exceed end time')
          setVenueStatus({isOpen:true,statusMessage:"Open",additionalInfo:"Closes at",time:formatToAmPm(todaysTimestamp.endTime)})
        }
        else if (!item?.isOnline && currentFormattedTime >= startFormatted && currentFormattedTime <= endFormatted) {
          console.log('Temporarily closed',);
          setVenueStatus({isOpen:false,statusMessage:"Temporarily Closed",additionalInfo:"",time:""})
          // setVenueStatus(false)

        }
        else if (item?.isOnline && currentFormattedTime >= endFormatted) {
          setVenueStatus({isOpen:true,statusMessage:"Open",additionalInfo:"",time:""})
          console.log('Always open');
          // setVenueStatus(true)
        }
        else if (!item?.isOnline && currentFormattedTime <= startFormatted) {
          console.log('today');
          setVenueStatus({isOpen:false,statusMessage:"Closed",additionalInfo:"Open at",time:formatToAmPm(todaysTimestamp.startTime)})
          // setVenueStatus(false)
          console.log('closed before start time')
        }
        else {
          console.log('closed');
          NextDay()
          setVenueStatus({isOpen:false,statusMessage:"Closed",additionalInfo:"Open at",time:formatToAmPm(todaysTimestamp.startTime)})
          // setVenueStatus(false)
        }
      };

    }
  }


  const NextDay = () => {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const nextDayName = nextDay
      .toLocaleDateString("en-US", { weekday: "long" })
      .toLowerCase();
    setCurrentDay(nextDayName);
  };


  const formatSingleTime = (time) => {
    if (/^\d{2}:\d{2}:\d{2}$/.test(time)) {
      return time;
    }
    const date = new Date(time);
    let hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const formatToAmPm = (time) => {
    if (/^\d{2}:\d{2}(:\d{2})?$/.test(time)) {
      const [hours, minutes] = time.split(':')
      const hoursNum = parseInt(hours, 10);
      const amPm = hoursNum >= 12 ? 'PM' : 'AM';
      const formattedHours = (hoursNum % 12) || 12
      return `${formattedHours.toString().padStart(2, '0')}:${minutes} ${amPm}`;
    }
    const date = new Date(time);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours.toString().padStart(2, "0")}:${minutes} ${amPm}`;
  };

  return { currentDay, addToCartDisabledDay, currentFormattedTime, formatTime, formattedTimes, showTime, venueStatus };
};
