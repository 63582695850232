// import { Helmet } from "react-helmet-async";
// import { Box, Typography } from "@mui/material";
// import AuthVerifyCodeForm from "../../sections/auth/AuthVerifyCodeForm";
// import verifyImg from "../../assets/icons/Verify.svg";
// import CodeVerified from "../../assets/icons/codeVerified.svg";
// import VerifySuccess from "./VerifySuccess";
// import { useEffect, useState } from "react";
// import { getVerifyCode } from "src/utils/Apis";

// export default function VerifyCodePage() {
//   const [showVerified, setShowVerified] = useState(false);
//   const [isCodeFormFilled, setIsCodeFormFilled] = useState(false);
//   const [verificationPath, setVerificationPath] = useState("Phone");

//   const handleChangeVerificationPath = () => {
//     setVerificationPath("Email");
//   };

//   const updateIsCodeFormFilled = (filled) => {
//     setIsCodeFormFilled(filled);
//   };

//   const getOTP = async (data, id) => {
//     try {
//       await getVerifyCode(data, id)
//     }
//     catch (error) {
//       console.log('error', error);
//     }
//   }

//   useEffect(() => {
//     const getResgisteredUserId = localStorage.getItem("reg_Id");
//     const apiData = {
//       isEmail: verificationPath === "Email" ? "true" : "false",
//       isPhone: verificationPath === "Phone" ? "true" : "false",
//     };
//     getOTP(apiData, getResgisteredUserId)
//   }, [verificationPath]);

//   return (
//     <>
//       {!showVerified && (
//         <>
//           <Helmet>
//             <title> Verify Code | Egora</title>
//           </Helmet>
//           <Box
//             display="flex"
//             justifyContent="center"
//           >
//             <img
//               src={isCodeFormFilled ? CodeVerified : verifyImg}
//               alt="verification"
//               style={{ width: "200px", height: "200px" }}
//             />
//           </Box>
//           <Typography variant="h3" paragraph>
//             Verification
//           </Typography>
//           <Typography sx={{ color: "text.secondary", mb: 5 }}>
//             Enter the four digit code sent to your
//             {verificationPath === "Email" ? "email address" : "mobile number"}
//           </Typography>
//           <AuthVerifyCodeForm
//             handleChangeVerificationPath={handleChangeVerificationPath}
//             verificationPath={verificationPath}
//             handleVerified={setShowVerified}
//             updateIsCodeFormFilled={updateIsCodeFormFilled}
//           />
//         </>
//       )}

//       {showVerified && <VerifySuccess />}
//     </>
//   );
// }

import { Helmet } from "react-helmet-async";
import { Box, Typography } from "@mui/material";
import AuthVerifyCodeForm from "../../sections/auth/AuthVerifyCodeForm";
import verifyImg from "../../assets/icons/Verify.svg";
import CodeVerified from "../../assets/icons/codeVerified.svg";
import VerifyEmailSuccess from "../../assets/icons/emailVerifiySuccess.svg"
import VerifyEmailProcess from "../../assets/icons/emailVerifiyProcess.svg"
import VerifySuccess from "./VerifySuccess";
import { useEffect, useState } from "react";
import { getVerifyCode } from "src/utils/Apis";
import VenueHeader from "../Venue/VenueHeader";
import { useLocation } from 'react-router-dom';

export default function VerifyCodePage() {

  const location = useLocation();
  const verificationPathForProfile = location.state?.profilePath
  const queryParams = new URLSearchParams(location.search);
  const verificationType = queryParams.get('verify');
  const [showVerified, setShowVerified] = useState(false);
  const [isCodeFormFilled, setIsCodeFormFilled] = useState(false);
  const [verificationPath, setVerificationPath] = useState(verificationType);
  const [profilePath, setRedirectPath] = useState(verificationPathForProfile);

  const handleChangeVerificationPath = () => {
    setVerificationPath("Email");
  };

  const updateIsCodeFormFilled = (filled) => {
    setIsCodeFormFilled(filled);
  };

  // const getOTP = async (data, id) => {
  //   try {
  //     await getVerifyCode(data, id)
  //   }
  //   catch (error) {
  //     console.log('error', error);
  //   }
  // }

  // useEffect(() => {
  //   const getResgisteredUserId = localStorage.getItem("reg_Id");
  //   const apiData = {
  //     isEmail: verificationPath === "Email" ? "true" : "false",
  //     // isPhone: verificationPath === "Phone" ? "true" : "false",
  //   };
  //   getOTP(apiData, getResgisteredUserId)
  // }, [verificationPath]);

  return (
    <>
      <VenueHeader />
      {!showVerified && (
        <>
          <Helmet>
            <title> Verify Code | Egora</title>
          </Helmet>
          <Box display="flex" justifyContent="center" mt={10}>
            <img
              src={verificationPath == 'Email'? VerifyEmailProcess : verificationPath == 'Phone'? verifyImg : CodeVerified }
              alt="verification"
              style={{ width: "200px", height: "200px" }}
            />
          </Box>
          <Typography variant="h3" paragraph>
            Verification
          </Typography>
          <Typography sx={{ color: "text.secondary", mb: 5 }}>
            Enter the four digit code sent to your 
            {verificationPath === "Email" ? " email address" : " mobile number"}
          </Typography>
          <AuthVerifyCodeForm
            handleChangeVerificationPath={handleChangeVerificationPath}
            verificationPath={verificationPath}
            profilePath={profilePath}
            handleVerified={setShowVerified}
            updateIsCodeFormFilled={updateIsCodeFormFilled}
          />
        </>
      )}

      {showVerified && <VerifySuccess />}
    </>
  );
}
