import { getGeocode, getLatLng } from "use-places-autocomplete";
import { getVenueListWithLocationAndType } from "src/redux/slices/venueSlice";
import { resetVenueIdtoBlock } from "src/redux/slices/VenueLocationModalSlice";

export async function selectedVenue(dispatch, type, val) {
  try {
    const address = localStorage.getItem("address");
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    const location = `${lat},${lng}`;

    if (type === "QrDineIn") {
      localStorage.setItem("mode", "QrDineIn");
    } else if (type === "QrPickUp") {
      localStorage.setItem("mode", "QrPickUp");
    } else if (type == "isDineIn") {
      dispatch(resetVenueIdtoBlock())
      localStorage.setItem("mode", "dineIn");
    } else {
      dispatch(resetVenueIdtoBlock())
      localStorage.setItem("mode", "pickUp");
    }
    localStorage.setItem("address", results[0]?.formatted_address);
    await dispatch(getVenueListWithLocationAndType(location, type, val));
  } catch (error) {
    console.log("Error ", error);
  }
}
