import { Helmet } from "react-helmet-async";
import VenueHeader from "../VenueHeader";
import FooterLogin from "src/layouts/compact/FooterLogin";
import UserProfile from "./UserProfile";
import { Stack } from "@mui/material";
import {
  StyledRootForFom,
  StyledSignUpForProfile,
} from "src/layouts/login/styles";

const Profile = () => {
  return (
    <div>
      <Helmet>
        <title>Egora-profile</title>
      </Helmet>
      <VenueHeader />
      <StyledRootForFom
        sx={{
          marginTop: "130px",
         
        }}
      >
        <StyledSignUpForProfile>
          <Stack sx={{ width: 1 }}>
            <UserProfile />
          </Stack>
        </StyledSignUpForProfile>
      </StyledRootForFom>

      <FooterLogin />
    </div>
  );
};
export default Profile;
