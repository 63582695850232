import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const VenueModalAction = ({
  quantity,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  selectedSauces,
  calculateTotalPrice,
  addItemToCart,
  selectedVariant,
  isLoading,
  venuesCoverData,
  venueIdToBlock,
  venueId
}) => {
  const { choiceByGroup } = useSelector((state) => state.choiceByGroup);
  const isRequiredGroupSelected = (groupId) => {
    const selectedGroup = selectedSauces.items.find(
      (item) => item.id === groupId
    );
    return (
      selectedGroup && selectedGroup.items.length >= selectedGroup.quantity
    );
  };

  const areAllRequiredGroupsSelected = () => {
    return choiceByGroup?.groups?.length > 0
      ? choiceByGroup.groups.every(
        (group) => !group.required || isRequiredGroupSelected(group.id)
      )
      : true;
  };

  const areAllRequiredVariantsSelected = () => {
    return selectedVariant?.groups?.length > 0
      ? selectedVariant.groups.every(
        (group) => !group.required || isRequiredGroupSelected(group.id)
      )
      : true;
  };

  const isButtonDisabled =
    (choiceByGroup?.groups?.length > 0 ||
      selectedVariant?.groups?.length > 0) &&
    (!areAllRequiredGroupsSelected() || !areAllRequiredVariantsSelected());

  

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: { xs: "wrap", sm: "nowrap" },
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "25%" },
          marginTop: "5px",
          borderRadius: "20px",
          border: "2px solid #F08203",
          padding: { xs: "3px 0px", sm: "0" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button onClick={handleDecreaseQuantity}>
            <Iconify
              sx={{
                color: "#F08203",
                height: 25,
                width: 25,
              }}
              icon={"ic:baseline-minus"}
            />
          </Button>
          <Typography
            sx={{
              fontSize: "20px",
              color: "#F08203",
            }}
          >
            {quantity}
          </Typography>
          <Button onClick={handleIncreaseQuantity}>
            <Iconify
              sx={{
                color: "#F08203",
                height: 25,
                width: 25,
              }}
              icon={"ic:baseline-plus"}
            />
          </Button>
        </Box>
      </Box>
      <LoadingButton
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
        onClick={addItemToCart}
        disabled={!venuesCoverData?.detail?.isOnline ? true : (venueId == venueIdToBlock)? true : isButtonDisabled}
        sx={{
          width: { xs: "100%", sm: "60%" },
          fontSize: "16px",
          padding: "8px 0px",
          marginTop: { xs: "20px", sm: "0" },
          bgcolor: "#fda92d",
          color: (theme) =>
            theme.palette.mode === "black" ? "common." : "grey.800",
          "&:hover": {
            bgcolor: "#B66816",
            color: (theme) =>
              theme.palette.mode === "black" ? "common.white" : "grey.800",
          },
        }}
      >
        Add to Cart -
        <Typography
          sx={{
            fontWeight: "700",
            ml: 1,
            fontSize: "16px",
          }}
        >
          {calculateTotalPrice()}
        </Typography>
      </LoadingButton>
    </Box>
  );
};

export default VenueModalAction;
