import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { clearTableData } from "../redux/slices/tableSlice";
import { useDispatch } from "react-redux";

const useGeolocation = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);
  const [address, setAddress] = useState(null); 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLocation = async (handleOpenModal) => {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            getAddress(position.coords.latitude, position.coords.longitude);
            setError(null);
            resolve(`${position.coords.latitude},${position.coords.longitude}`);
          },
          (error) => {
            handleOpenModal()
            setError(error.message);
            reject(error.message);
          }
        );
      });
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const getAddress = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
      );
      const formattedAddress = response.data.results[0].formatted_address;
      setAddress(formattedAddress)
      localStorage.setItem("tempAddress", formattedAddress);
      let mode = localStorage.getItem("mode");
      if(!mode){
        localStorage.removeItem("redux-table");
        localStorage.setItem("mode", "dineIn");
      }

    } catch (error) {
      console.log("error", error);
    }
  };

  const triggerLocation = async (handleOpenModal) => {
    const result = await getLocation(handleOpenModal);
    return result
  };

  return { latitude, longitude, error,address, triggerLocation };
};

export default useGeolocation;
