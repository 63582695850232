import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import NewVenueCard from "./NewVenueCard";
import { useSelector } from "react-redux";
import EmptyContent from "src/components/empty-content/EmptyContent";

const NewVenue = ({ searchQuery }) => {
  const { venues } = useSelector((state) => state.venues) || {}; 
  const filteredVenues = Array.isArray(venues)
    ? venues
        .filter((venue) => venue?.distance)
        .filter((venue) =>
          (venue.name || '').toLowerCase().includes(searchQuery.toLowerCase())
        )
        .slice(-4)
        .reverse() 
    : []; 
  return (
    <Box
      sx={{
        marginBottom: "70px",
      }}
    >
      <Box
        mt={3}
        sx={{
          fontSize: "16px",
        }}
      >
        <Typography variant="h3">New on Egora</Typography>
      </Box>

      {filteredVenues.length > 0 ? (
        <Grid container spacing={2}>
          {filteredVenues.map((venue) => (
            <Grid item key={venue.id} xs={12} sm={6} md={4} lg={3}>
              <NewVenueCard item={venue} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyContent
          title="No Data found"
          sx={{ "& span.MuiBox-root": { height: 160 } }}
        />
      )}
    </Box>
  );
};

export default NewVenue;

