import { Box, Divider, IconButton, Typography, Link as MuiLink  } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { DialogTitle, DialogContent } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom"; 

const OrderStatusModal = ({ width, orderStatusModal, onClose }) => {

  const { isLoggedIn } = useSelector((state) => state.login);

  return (
    <>
      <ConfirmDialog width={width} open={orderStatusModal?.openModal} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box variant="h2">
                {orderStatusModal?.detail?.state === "abort" && (
                  <Typography variant="h6" component="h2">
                    Your order has been declined from {orderStatusModal?.detail?.venueId?.name}.
                  </Typography>
                )}
                {orderStatusModal?.detail?.state === "completed" && (
                  <Typography variant="h6" component="h2">
                    Your order has been completed.
                  </Typography>
                )}
              </Box>
            </Box>
            <IconButton aria-label="close" onClick={onClose}>
              <Iconify
                sx={{
                  height: 25,
                  width: 25,
                }}
                icon="mdi:close"
                color="#fca92e"
              />
            </IconButton>
          </Box>
          {isLoggedIn &&
            <Box mt={3}>
              <Divider />
            </Box>
          }
        </DialogTitle>

        {isLoggedIn &&
          <DialogContent sx={{ typography: "body2" }}>
            <MuiLink 
            sx={{fontWeight:'bold',display: 'block', mb: 3 }}
              component={RouterLink} 
              to={'/order'} 
              underline="hover" 
              color="primary"
            >
              Go to Order
            </MuiLink>
          </DialogContent>
        }
      </ConfirmDialog>
    </>
  );
};
export default OrderStatusModal;
