import UpdatePassword from "./updatePassword";
import UpdateUserProfileInfo from "./updateProfileInfo";
import UpdateUserEmailOrPhone from "./updateEmailOrPhone";
import UserCarInformation from "./UserCarInformation";
import UserSaveCardInfo from "./UserSaveCardInfo";

export default function UserProfile() {
  return (
    <>
      <UpdateUserProfileInfo />
      <UpdateUserEmailOrPhone />
      <UpdatePassword />
      <UserCarInformation/>
      <UserSaveCardInfo/>
    </>
  );
}
