import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import previousOrderType from "src/utils/previousOrderType";


const VenueSkipModalContent = ({ venueId, onClose }) => {

  const [message, setMessage] = useState(null);

  const { tableData } = useSelector((state) => state.tableData);
  const { cardItems } = useSelector((state) => state.cardSlice);
  const { venues } = useSelector((state) => state.venues);
  const venue = venues.find((venue) => venue.id === tableData?.venueId);

  useEffect(() => {
    const fetchPreviousOrderType = async () => {
      if (cardItems?.data?.items?.length > 0) {
        const prevType = await previousOrderType(venueId);
        if (prevType === "Set DineIn") {
          setMessage("You already have scanned a QR of other venue To DineIn, Would You like to discard that")
        } else if (prevType === "Set PickUp") {
          setMessage("You already have scanned a QR of other venue To PickUp, Would You like to discard that")
        }
      }
    };

    fetchPreviousOrderType();
  }, [venueId]);


  return (
    <>
      {message ? (
        <Typography mt={3}>{message}</Typography>
      ) : (
        tableData && tableData.venueId && venueId !== tableData.venueId  && cardItems?.data?.items?.length > 0 ? (
          <Typography mt={3}>
            You have already set the table at
            <Link
              to={`/venue/${tableData?.venueId}`}
              style={{ textDecoration: "none", color: "#FCA92E", marginLeft: '5px' }}
              onClick={onClose}
            >
              {venue?.name}.
            </Link>
          </Typography>
        ) : (
          cardItems?.data?.items?.length > 0 && <Typography mt={3}>You have some items in your cart.</Typography> 
        )
      )}
    </>
  );
};

export default VenueSkipModalContent;
